import {
  FaQuestion,
  FaRocket,
  FaRss,
  FaSignal,
  FaVolumeUp,
  FaWind,
} from 'react-icons/fa';

export const getTypeIcon = (type: string = '', size: number = 16) => {
  if (type.startsWith('Mark')) {
    return <FaSignal size={size} />;
  }

  if (type.startsWith('Accel')) {
    return <FaRocket size={size} />;
  }

  if (type.startsWith('Luftstöt')) {
    return <FaWind size={size} />;
  }

  if (type.startsWith('Buller')) {
    return <FaVolumeUp size={size} />;
  }

  if (type.startsWith('Stomljud')) {
    return <FaRss size={size} />;
  }

  return <FaQuestion size={size} />;
};
