import { formatDistance, formatDistanceStrict } from 'date-fns';
import { formatToTimeZone, parseFromTimeZone } from 'date-fns-timezone';
import { sv } from 'date-fns/locale';
import { useMemo } from 'react';

import { Tooltip } from '@material-ui/core';

const options = {
  locale: sv,
};

const NiceDate = ({
  date,
  strict = false,
  dateFormat = 'YYYY-MM-DD HH:mm:ss',
  isUtc = false,
  convertToDistance = true,
  alreadyLocal = false,
  className = undefined,
}) => {
  const parsedDate = useMemo(
    () =>
      date &&
      parseFromTimeZone(date, {
        timeZone: isUtc ? 'Etc/GMT' : 'Europe/Stockholm',
      }),
    [date, isUtc]
  );

  const formattedDate = useMemo(
    () =>
      parsedDate &&
      formatToTimeZone(parsedDate, dateFormat, {
        timeZone: alreadyLocal ? 'Etc/GMT' : 'Europe/Stockholm',
      }),
    [alreadyLocal, dateFormat, parsedDate]
  );

  const niceStr = useMemo(() => {
    if (parsedDate == null) {
      return null;
    }

    const currentDate = new Date();

    return `${
      strict
        ? formatDistanceStrict(parsedDate, currentDate, options)
        : formatDistance(parsedDate, currentDate, options)
    } sedan`;
  }, [parsedDate, strict]);

  if (date == null) {
    return null;
  }

  return convertToDistance ? (
    <Tooltip title={formattedDate}>
      <span className={className}>{niceStr}</span>
    </Tooltip>
  ) : (
    <span className={className}>{formattedDate}</span>
  );
};

export default NiceDate;
