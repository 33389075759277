import { GeoJsonObject } from 'geojson';
import L from 'leaflet';
import { VFC, useEffect, useState } from 'react';
import { GeoJSON, LayersControl, WMSTileLayer } from 'react-leaflet';

type LayerType = 'wms';

interface LayerOptions {
  weight?: number;
  fillOpacity?: number;
  opacity?: number;
  layers?: string;
  format?: string;
  transparent?: boolean;
}

interface LayerConfig {
  disabled?: boolean;
  url: string;
  name: string;
  default: true;
  type?: LayerType;
  options?: LayerOptions;
}

interface LayerData {
  id: string;
  name: string;
  type?: LayerType;
  default?: boolean;
  url?: string;
  data?: GeoJsonObject;
  options?: LayerOptions;
}

type ProjectConfig = { [id: string]: LayerConfig };

const fetchJson = async <T,>(url: string) => {
  try {
    const response = await fetch(url);
    return (await response.json()) as T;
  } catch (error) {
    console.warn('failed to fetch json', url, error);
  }
};

const fetchLayerData = async (projectNumber: string): Promise<LayerData[]> => {
  const data = await fetchJson<ProjectConfig>(
    `/geodata/${projectNumber}/config.json`
  );

  let layers: LayerData[] = [];

  if (data == null) {
    return layers;
  }

  for (const [id, layer] of Object.entries(data)) {
    if (layer.disabled) {
      continue;
    }

    const data =
      layer.type !== 'wms'
        ? await fetchJson<GeoJsonObject>(layer.url)
        : undefined;

    layers.push({
      id: id,
      name: layer.name,
      type: layer.type,
      default: layer.default,
      data: data,
      url: layer.url,
      options: layer.options,
    });
  }

  return layers;
};

interface ProjectLayersProps {
  projectNumber: string;
}

export const ProjectLayers: VFC<ProjectLayersProps> = ({ projectNumber }) => {
  const [layerData, setLayerData] = useState<LayerData[]>([]);

  useEffect(() => {
    (async () => {
      setLayerData(await fetchLayerData(projectNumber));
    })();
  }, [projectNumber]);

  if (layerData.length === 0) {
    return null;
  }

  return (
    <LayersControl>
      {Object.values(layerData).map((layer) => (
        <LayersControl.Overlay
          name={layer.name}
          checked={layer.default}
          key={layer.id}
        >
          {layer.type === 'wms' && layer.url != null ? (
            <WMSTileLayer
              url={layer.url}
              layers={layer.options?.layers}
              format={layer.options?.format}
              transparent={layer.options?.transparent}
              opacity={layer.options?.opacity}
            />
          ) : (
            layer.data != null && (
              <GeoJSON
                data={layer.data}
                // Do not create any points. We don't support that at the moment.
                pointToLayer={() => undefined as unknown as L.Marker}
              />
            )
          )}
        </LayersControl.Overlay>
      ))}
    </LayersControl>
  );
};
