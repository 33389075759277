import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerText: {
    color: theme.palette.text.hint,
  },
  img: {
    width: '12em',
    height: '10em',
    marginTop: 20,
  },
});

function Unauthorized({ classes }) {
  return (
    <div className={classes.container}>
      <Typography variant='h6' color='textSecondary' gutterBottom>
        Du saknar behörighet
      </Typography>
      <Typography className={classes.containerText}>
        Kontakta din systemadministratör om du vill ha tillgång till denna sida
      </Typography>

      <img
        className={classes.img}
        src='/images/unauthorized.svg'
        alt='Behörighet saknas'
      />
    </div>
  );
}

export default withStyles(styles)(Unauthorized);
