import L from 'leaflet';

export interface Highlightable {
  highlight: () => void;
  fade: () => void;
  reset: () => void;
}

export const createHighlightable = (
  map: L.Map,
  marker: L.Marker,
  baseOpacity: number
): Highlightable => {
  const circle = new L.CircleMarker(
    [marker.getLatLng().lat, marker.getLatLng().lng],
    {
      radius: 12,
    }
  );

  const highlight = () => {
    circle.addTo(map);
    marker.setOpacity(1);
  };

  const fade = () => {
    marker.setOpacity(0.3);
  };
  const reset = () => {
    if (map.hasLayer(circle)) {
      map.removeLayer(circle);
    }
    marker.setOpacity(baseOpacity);
  };

  return {
    highlight,
    fade,
    reset,
  };
};
