import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  DistributedTracingModes,
} from '@microsoft/applicationinsights-web';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export const AppInsightsProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const reactPlugin = useMemo<ReactPlugin>(() => new ReactPlugin(), []);

  useEffect(() => {
    const connectionString =
      process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;
    if (!connectionString) {
      console.log('Application Insights not configured.');
      return;
    }

    const appInsights = new ApplicationInsights({
      config: {
        connectionString,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: false,
        disableFetchTracking: false,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: ['lantmateriet.se'], // Don't send tracing headers to lantmäteriet.
        enableUnhandledPromiseRejectionTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history },
        },
      },
    });

    appInsights.loadAppInsights();
  }, [history, reactPlugin]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};
