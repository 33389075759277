import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Hook for handling the service worker. This should only be instanced once in the application.
 */
export const useServiceWorker = () => {
  const history = useHistory();

  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>();

  const [active, setActive] = useState<ServiceWorker | null>();
  const [waiting, setWaiting] = useState<ServiceWorker | null>();
  const [installing, setInstalling] = useState<ServiceWorker | null>();

  // Initialize registration and set up `controllerchange` event handler.
  useEffect(() => {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    let reloading = false;
    const controllerChangeHandler = () => {
      if (!reloading) {
        window.location.reload();
        reloading = true;
      }
    };

    (async () => {
      const r = await navigator.serviceWorker.getRegistration(
        process.env.PUBLIC_URL
      );
      setRegistration(r);
    })();

    navigator.serviceWorker.addEventListener(
      'controllerchange',
      controllerChangeHandler
    );

    return () => {
      navigator.serviceWorker.removeEventListener(
        'controllerchange',
        controllerChangeHandler
      );
    };
  }, []);

  // Set up location listener that checks for updates on navigation.
  useEffect(() => {
    if (!registration) {
      return;
    }

    const locationListener = () => {
      if (navigator.onLine) {
        registration.update().catch((error) => {
          console.warn('service worker update check failed.', error);
        });
      }
    };

    const unregister = history.listen(locationListener);
    return () => {
      unregister();
    };
  }, [history, registration]);

  // Set active and waiting instances and register update event handler.
  useEffect(() => {
    const updateFoundHandler = () => {
      setInstalling(registration?.installing);
    };

    setActive(registration?.active);
    setWaiting(registration?.waiting);
    registration?.addEventListener('updatefound', updateFoundHandler);

    return () => {
      registration?.removeEventListener('updatefound', updateFoundHandler);
    };
  }, [registration]);

  // Set installing event handler.
  useEffect(() => {
    if (!installing) {
      return;
    }

    const stateChangeHandler = () => {
      if (installing?.state !== 'installed') {
        return;
      }

      setInstalling(null);

      if (navigator.serviceWorker.controller) {
        setWaiting(registration?.waiting);
      } else {
        setActive(navigator.serviceWorker.controller);
      }
    };

    installing.addEventListener('statechange', stateChangeHandler);

    return () => {
      installing.removeEventListener('statechange', stateChangeHandler);
    };
  }, [installing, registration]);

  // Signal service worker to skip waiting.
  const skipWaiting = useCallback(() => {
    if (!waiting) {
      return;
    }

    waiting.postMessage({ type: 'SKIP_WAITING' });
  }, [waiting]);

  return {
    active,
    haveWaiting: !!waiting,
    skipWaiting,
  };
};
