import styled from '@emotion/styled';
import { VFC, useState } from 'react';
import { MdBolt } from 'react-icons/md';

import { Button, Grid, Typography } from '@material-ui/core';

import { MarkerBackground } from '../../../components/map/icon-marker';
import {
  CustomControlProps,
  CustomMapControl,
} from '../../../components/map/map-custom-control';
import { getTypeIcon } from './icon-common';

interface ListDivProps {
  open: boolean;
}

const ListDiv = styled.div<ListDivProps>((props) => ({
  width: props.open ? 270 : 0,
  maxHeight: props.open ? 580 : 0,
  overflowY: 'auto',
  padding: props.open ? 5 : 0,
}));

const WhiteBackgroundDiv = styled.div({
  backgroundColor: 'white',
  borderRadius: '4px',
});

interface MarkerLegendProps extends CustomControlProps {}

export const MarkerLegend: VFC<MarkerLegendProps> = ({ ...controlProps }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomMapControl {...controlProps}>
      <WhiteBackgroundDiv>
        <ListDiv open={isOpen}>
          <div style={{ paddingLeft: 15 }}>
            <Typography>Mätpunkter:</Typography>
          </div>
          <Grid container spacing={0} justifyContent='center'>
            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='gray'
              >
                {getTypeIcon('Mark')}
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Vibration</Typography>
            </Grid>
            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='gray'
              >
                {getTypeIcon('Accel')}
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Acceleration</Typography>
            </Grid>
            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='gray'
              >
                {getTypeIcon('Luftstöt')}
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Luftstöt</Typography>
            </Grid>
            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='gray'
              >
                {getTypeIcon('Buller')}
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Buller</Typography>
            </Grid>
            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='gray'
              >
                {getTypeIcon('Stomljud')}
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Stomljud</Typography>
            </Grid>
          </Grid>
          <div style={{ paddingLeft: 15 }}>
            <Typography>Salvor:</Typography>
          </div>
          <Grid container spacing={0} justifyContent='center'>
            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='gray'
              >
                <MdBolt />
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Salva</Typography>
            </Grid>

            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='blue'
              >
                <MdBolt />
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Riktvärdet innehålls</Typography>
            </Grid>

            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='beige'
              >
                <MdBolt />
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>80% av riktvärdet överskridit</Typography>
            </Grid>

            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='orange'
              >
                <MdBolt />
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Undre riktvärdet överskridit</Typography>
            </Grid>

            <Grid item xs={2}>
              <MarkerBackground
                style={{ marginLeft: 5, marginTop: 5 }}
                iconColor='white'
                markerColor='red'
              >
                <MdBolt />
              </MarkerBackground>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>Riktvärdet överskridit</Typography>
            </Grid>
          </Grid>
        </ListDiv>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Teckenförklaring
          </Button>
        </div>
      </WhiteBackgroundDiv>
    </CustomMapControl>
  );
};
