import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';
import L from 'leaflet';
import Proj4 from 'proj4';
import { VFC, useMemo } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';

import { Grid, Link, useTheme } from '@material-ui/core';

import { crsValues } from '../../../components/map/crs';
import {
  MarkerBackground,
  MarkerColor,
  MarkerIcon,
  createMarkerIcon,
} from '../../../components/map/icon-marker';
import { Highlightable, createHighlightable } from './highlightable';
import { getTypeIcon } from './icon-common';
import { MeasuringPointExt, isMeasuringPointActive } from './model';

const convertCoordinates = (lat: number, long: number, crs: number) => {
  const cs = crsValues.filter((c) => c.crs === crs)[0];
  const proj = Proj4(cs.def, Proj4.WGS84);
  const c = [long, lat];
  const projected = proj.inverse(c);
  let decimals = 1;
  if (cs.axis === 'yx') {
    decimals = 5;
    projected.reverse();
  }

  return {
    x: L.Util.formatNum(projected[0], decimals).toString().replace('.', ','),
    y: L.Util.formatNum(projected[1], decimals).toString().replace('.', ','),
    crsName: cs.name,
  };
};

export interface MeasurePointMarker extends Highlightable {
  measuringPoint: MeasuringPointExt;
  marker: L.Marker;
  Component: VFC<{ color?: MarkerColor }>;
}

export const createMeasurePointMarker = (
  measuringPoint: MeasuringPointExt,
  map: L.Map
): MeasurePointMarker => {
  const icon = createMarkerIcon(true);

  const baseOpacity = isMeasuringPointActive(measuringPoint) ? 1 : 0.6;
  const marker = new L.Marker(
    [measuringPoint.latitude!, measuringPoint.longitude!],
    {
      icon: icon,
      opacity: baseOpacity,
    }
  );

  marker.bindTooltip(`MP ${measuringPoint.number}`, {
    direction: 'top',
    opacity: 1,
    permanent: true,
  });

  const popup = document.createElement('div');
  marker.bindPopup(popup);

  return {
    measuringPoint,
    marker,
    ...createHighlightable(map, marker, baseOpacity),
    Component: ({ color }) => (
      <MeasuringPointMarkerComponent
        key={measuringPoint.id}
        measuringPoint={measuringPoint}
        color={color}
        icon={icon}
        popup={popup}
      />
    ),
  };
};

const PopupTitle = styled.div({
  display: 'flex',
  paddingBottom: 16,
  fontSize: 16,
  fontWeight: 800,
  alignItems: 'center',
});

interface MeasuringPointMarkerComponentProps {
  measuringPoint: MeasuringPointExt;
  icon: MarkerIcon;
  popup: HTMLElement;
  color?: MarkerColor;
}

const MeasuringPointMarkerComponent: VFC<
  MeasuringPointMarkerComponentProps
> = ({ measuringPoint, icon, popup, color }) => {
  const theme = useTheme();
  const location = useMemo(
    () =>
      // TODO: check that we have coordinates!
      convertCoordinates(
        measuringPoint.latitude!,
        measuringPoint.longitude!,
        measuringPoint.crs!
      ),
    [measuringPoint.crs, measuringPoint.latitude, measuringPoint.longitude]
  );

  const mpType = useMemo(() => {
    const channelsTypes = measuringPoint.channels.map((c) => c.type);
    const uniqueChannelTypes = [...new Set(channelsTypes)];
    return uniqueChannelTypes.join(' / ');
  }, [measuringPoint.channels]);

  const dataLink = useMemo(() => {
    const page =
      measuringPoint.type === 'Buller' || measuringPoint.type === 'Stomljud'
        ? 'noise'
        : 'measuringdata';
    return `/project/${measuringPoint.projectNumber}/${page}?mp=${measuringPoint.id}`;
  }, [measuringPoint.id, measuringPoint.projectNumber, measuringPoint.type]);

  return (
    <>
      {createPortal(
        <MarkerBackground iconColor='white' markerColor={color ?? 'gray'}>
          {getTypeIcon(measuringPoint.type)}
        </MarkerBackground>,
        icon.icon
      )}
      {createPortal(
        <>
          <PopupTitle>
            {getTypeIcon(measuringPoint.type, 20)}
            <span
              style={{ marginLeft: theme.spacing(0.5) }}
            >{`MP ${measuringPoint.number}`}</span>
          </PopupTitle>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              Adress:
            </Grid>
            <Grid item xs={8}>
              {measuringPoint.address}
            </Grid>

            <Grid item xs={4}>
              Koordinater:
            </Grid>
            <Grid item xs={8}>
              {`${location.crsName} x:${location.x} y: ${location.y} z: ${
                measuringPoint.z ?? '-'
              }`}
            </Grid>

            <Grid item xs={4}>
              Mätpunkt inlagd:
            </Grid>
            <Grid item xs={8}>
              {measuringPoint.isAddedOnMap == null
                ? '-'
                : measuringPoint.isAddedOnMap
                ? 'Via Karta'
                : ' Via koordinater'}
            </Grid>

            <Grid item xs={4}>
              Arbete:
            </Grid>
            <Grid item xs={8}>
              {measuringPoint.typeOfWork}
            </Grid>

            <Grid item xs={4}>
              Typ:
            </Grid>
            <Grid item xs={8}>
              {mpType}
            </Grid>

            {measuringPoint.typeOfWork === 'Sprängning' &&
              measuringPoint.v1 &&
              measuringPoint.v2 && (
                <>
                  <Grid item xs={4}>
                    Riktvärde:
                  </Grid>
                  <Grid item xs={8}>
                    {`${measuringPoint.v1} - ${measuringPoint.v2}`}
                  </Grid>
                </>
              )}

            {measuringPoint.fixedBenchmark && (
              <>
                <Grid item xs={4}>
                  Riktvärde (manuellt):
                </Grid>
                <Grid item xs={8}>
                  {measuringPoint.fixedBenchmark}
                </Grid>
              </>
            )}

            {measuringPoint.typeOfWork === 'Sprängning' && (
              <>
                {measuringPoint.v10 && (
                  <>
                    <Grid item xs={4}>
                      V10:
                    </Grid>
                    <Grid item xs={8}>
                      {measuringPoint.v10}
                    </Grid>
                  </>
                )}

                {measuringPoint.airblast && (
                  <>
                    <Grid item xs={4}>
                      Luftstöt (Pa):
                    </Grid>
                    <Grid item xs={8}>
                      {measuringPoint.airblast}
                    </Grid>
                  </>
                )}
              </>
            )}

            {measuringPoint.noise && (
              <>
                <Grid item xs={4}>{`Buller (dBA):`}</Grid>
                <Grid item xs={8}>
                  {measuringPoint.noise}
                </Grid>
              </>
            )}

            <Grid item xs={4}>
              Aktiv:
            </Grid>
            <Grid item xs={8}>
              {measuringPoint.cards.map((card) => (
                <div key={card.id}>
                  {card.validFrom != null &&
                    format(parseISO(card.validFrom), 'yyyy-MM-dd')}
                  {' - '}
                  {card.validTo != null &&
                    parseISO(card.validTo) < new Date() &&
                    format(parseISO(card.validTo), 'yyyy-MM-dd')}
                </div>
              ))}
            </Grid>

            {/* Empty grid to align link to the right. */}
            <Grid item xs={4} />
            <Grid item xs={8}>
              <Link href={dataLink} target='_blank'>
                Visa mätdata
              </Link>
            </Grid>
          </Grid>
        </>,
        popup
      )}
    </>
  );
};
