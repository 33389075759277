const sweref = function (name: string, epsg: number, def: string) {
  return {
    crs: epsg,
    name: 'SWEREF99 ' + name + ' (EPSG:' + epsg + ')',
    axis: 'xy',
    axisNames: ['X', 'Y'],
    def: def,
  };
};

export const crsValues = [
  {
    crs: 4326,
    name: 'WGS84 / GPS (latitud/longitud)',
    axisNames: ['Latitud', 'Longitud'],
    axis: 'yx',
    def: '+proj=longlat +datum=WGS84 +no_defs ',
  },
  sweref(
    'TM',
    3006,
    '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '12 00',
    3007,
    '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  ),
  sweref(
    '13 30',
    3008,
    '+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '15 00',
    3009,
    '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '16 30',
    3010,
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '18 00',
    3011,
    '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '14 15',
    3012,
    '+proj=tmerc +lat_0=0 +lon_0=14.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '15 45',
    3013,
    '+proj=tmerc +lat_0=0 +lon_0=15.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '17 15',
    3014,
    '+proj=tmerc +lat_0=0 +lon_0=17.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '18 45',
    3015,
    '+proj=tmerc +lat_0=0 +lon_0=18.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '20 15',
    3016,
    '+proj=tmerc +lat_0=0 +lon_0=20.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '21 45',
    3017,
    '+proj=tmerc +lat_0=0 +lon_0=21.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  sweref(
    '23 15',
    3018,
    '+proj=tmerc +lat_0=0 +lon_0=23.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
];
