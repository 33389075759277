import { IconButton, styled } from '@material-ui/core';

export const ControlBackground = styled('div')({
  backgroundColor: '#FFF',
  borderRadius: 2,
});

export const ControlIconButton = styled(IconButton)({
  color: 'black',
  borderRadius: 2,
});
