import { createControlHook, createElementHook } from '@react-leaflet/core';
import L, {
  Control,
  ControlOptions,
  ControlPosition,
  Map as LeafletMap,
} from 'leaflet';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface WrapperControlProps extends ControlOptions {}

class WrapperControl extends Control {
  public container?: HTMLElement;

  public onAdd(map: LeafletMap): HTMLElement {
    this.container = L.DomUtil.create('div', 'leaflet-bar');
    return this.container;
  }
}

export interface CustomControlProps extends ControlOptions {
  position?: ControlPosition;
  disableClickPropagation?: boolean;
  disableScrollPropagation?: boolean;
}

export const CustomMapControl: React.FC<CustomControlProps> = (props) => {
  const useElement = createElementHook<WrapperControl, WrapperControlProps>(
    (props, context) =>
      Object.freeze({
        instance: new WrapperControl(props),
        context,
        container: null,
      })
  );
  const useControl = createControlHook(useElement);
  const { instance } = useControl({
    position: props.position ?? 'topright',
  }).current;

  const [container, setContainer] = useState(instance.container);

  useEffect(() => {
    setContainer(instance.container);
  }, [instance.container]);

  useEffect(() => {
    if (container != null && props.disableClickPropagation) {
      L.DomEvent.disableClickPropagation(container);
    }
  }, [container, props.disableClickPropagation]);

  useEffect(() => {
    if (container != null && props.disableScrollPropagation) {
      L.DomEvent.disableScrollPropagation(container);
    }
  }, [container, props.disableScrollPropagation]);

  if (container == null) {
    return null;
  }

  return ReactDOM.createPortal(props.children, container);
};
CustomMapControl.defaultProps = {
  disableClickPropagation: true,
};
