import { useApolloClient } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withRouter } from 'react-router';

import {
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { SEARCH_TERM } from '../cache-graphql';
import styles from './styles';

const LAST_SEARCHES = 'lastSearches';

const getLastSearches = () =>
  JSON.parse(localStorage.getItem(LAST_SEARCHES)) || [];

const Search = ({ classes, history, autoFocus }) => {
  const searchField = useRef(null);
  const [showLastSearches, setShowLastSearches] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const lastSearches = getLastSearches();
  const client = useApolloClient();

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setShowLastSearches(false);
    }, 500);

    if (!searchTerm || searchTerm === lastSearches[0]) return;

    localStorage.setItem(
      LAST_SEARCHES,
      JSON.stringify([searchTerm, ...lastSearches.slice(0, 4)])
    );
  }, [lastSearches, searchTerm]);

  const handleFocus = useCallback((event) => {
    setShowLastSearches(true);
  }, []);

  useEffect(() => {
    const handleChange = () => {
      const data = {
        projectSearch: {
          term: searchTerm,
          __typename: 'ProjectSearch',
        },
      };
      client?.writeQuery({
        query: SEARCH_TERM,
        data: data,
      });

      setShowLastSearches(false);
    };

    handleChange();

    // Close search on navigation
    history.listen(() => {
      setSearchTerm('');
    });
  }, [client, history, searchTerm]);

  const debouncedUpdate = useMemo(
    () =>
      _.debounce((searchTerm) => {
        setSearchTerm(searchTerm);
      }, 500),
    []
  );

  return (
    <>
      <InputBase
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => debouncedUpdate(e.target.value)}
        inputRef={searchField}
        autoFocus={autoFocus}
        placeholder='Sök'
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
      <Popper
        style={{ zIndex: 1301 }}
        open={showLastSearches}
        anchorEl={searchField.current}
        placement='bottom-start'
      >
        <Paper>
          <List dense>
            {lastSearches.map((x, i) => (
              <ListItem button key={x + i}>
                <ListItemText
                  onClick={() => {
                    setSearchTerm(x);
                    searchField.current.focus();
                  }}
                >
                  {x}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>
    </>
  );
};

export default withStyles(styles)(withRouter(Search));
