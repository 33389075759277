import styled from '@emotion/styled';
import * as CSS from 'csstype';
import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { GoDotFill } from 'react-icons/go';
import { Marker } from 'react-leaflet';
import { MarkerProps } from 'react-leaflet/types/Marker';

const MarkerOffset = {
  red: 0,
  darkred: -180,
  lightred: -360,
  orange: -36,
  beige: -396,
  green: -72,
  darkgreen: -252,
  lightgreen: -432,
  blue: -108,
  darkblue: -216,
  lightblue: -468,
  purple: -144,
  darkpurple: -288,
  pink: -504,
  cadetblue: -324,
  white: -574,
  gray: -648,
  lightgray: -612,
  black: -682,
} as const;

export type MarkerColor = keyof typeof MarkerOffset;

interface MarkerBackgroundProps {
  iconColor: CSS.Property.Color;
  markerColor: MarkerColor;
}

export const MarkerBackground = styled.div<MarkerBackgroundProps>((props) => ({
  width: 35,
  height: 46,
  marginLeft: -17,
  marginTop: -43,
  backgroundImage: 'url(/images/markers-soft.png)',
  backgroundPositionX: MarkerOffset[props.markerColor],
  color: props.iconColor,
  lineHeight: '40px',
  textAlign: 'center',
  fontSize: '20px',
}));

const MarkerShadow = styled.div({
  width: 35,
  height: 16,
  marginLeft: -9,
  marginTop: -13,
  backgroundImage: 'url(/images/markers-shadow.png)',
});

const defaultIcon = <GoDotFill />;

export interface IconMarkerProps extends Omit<MarkerProps, 'icon'> {
  icon?: React.ReactNode;
  iconColor?: CSS.Property.Color;
  markerColor?: MarkerColor;
  disableShadow?: boolean;
}

export const IconMarker = React.forwardRef<
  L.Marker,
  React.PropsWithChildren<IconMarkerProps>
>(
  (
    {
      icon = defaultIcon,
      iconColor = 'white',
      markerColor = 'blue',
      disableShadow = false,
      ...props
    },
    ref
  ) => {
    const [markerIcon, setMarkerIcon] = useState(() =>
      createMarkerIcon(disableShadow)
    );

    useEffect(() => {
      setMarkerIcon(createMarkerIcon(disableShadow));
    }, [disableShadow]);

    return (
      <>
        <Marker ref={ref} icon={markerIcon} {...props} />
        {ReactDOM.createPortal(
          <MarkerBackground iconColor={iconColor} markerColor={markerColor}>
            {icon}
          </MarkerBackground>,
          markerIcon.icon
        )}
        {!disableShadow &&
          markerIcon.shadow != null &&
          ReactDOM.createPortal(<MarkerShadow />, markerIcon.shadow)}
      </>
    );
  }
);

interface MarkerIconOptions extends L.BaseIconOptions {
  disableShadow?: boolean;
}

export const createMarkerIcon = (disableShadow: boolean) =>
  new MarkerIcon({
    popupAnchor: [1, -36],
    tooltipAnchor: [1, -28],
    disableShadow,
  });

export class MarkerIcon extends L.Icon<MarkerIconOptions> {
  public readonly icon = L.DomUtil.create('div', 'leaflet-marker-icon');
  public readonly shadow?: HTMLDivElement;

  public constructor(options?: MarkerIconOptions) {
    super(options ?? {});

    // Don't create shadow div if it's disabled
    if (options?.disableShadow !== true) {
      this.shadow = L.DomUtil.create('div', 'leaflet-marker-shadow');
    }
  }

  public createIcon(_oldIcon?: HTMLElement): HTMLElement {
    return this.icon;
  }

  public createShadow(_oldIcon?: HTMLElement): HTMLElement {
    return this.shadow as HTMLElement;
  }
}
