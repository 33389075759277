export const handleError = (e) => {
  const gqlErrors = e.graphQLErrors;
  if (gqlErrors && gqlErrors.length) {
    const error = gqlErrors[0];
    if (error.extensions && error.extensions.data) {
      const data = error.extensions.data;
      if (data.Message) {
        return data.Message;
      } else {
        if (error.message) {
          return error.message;
        }
      }
    } else if (error.message) {
      return error.message;
    }
  }

  throw e;
};

export const getErrorType = (e) => {
  const gqlErrors = e.graphQLErrors;
  if (gqlErrors && gqlErrors.length) {
    const error = gqlErrors[0];
    if (error.extensions && error.extensions.data) {
      const data = error.extensions.data;
      if (data.Type) return data.Type;
    }
  }

  return '';
};

export const filterObject = (obj, unwantedProperties) =>
  Object.fromEntries(
    Object.keys(obj)
      .filter((key) => unwantedProperties.indexOf(key) === -1)
      .map((key) => [key, obj[key]])
  );
