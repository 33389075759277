import classNames from 'classnames';
import gravatarUrl from 'gravatar-url';
import toMaterialStyle from 'material-color-hash';
import { useMemo } from 'react';

import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  image: {
    borderRadius: '50%',
    boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  smallAvatar: {
    width: 30,
    height: 30,
    fontSize: '16px',
  },
});

const UserAvatar = ({ user, small, isSkeleton }) => {
  const classes = useStyles();

  const userColor = useMemo(
    () => toMaterialStyle(user?.email ?? user?.name ?? '', 300),
    [user]
  );

  const url = useMemo(
    () =>
      !isSkeleton && user?.email?.indexOf('@') > 0
        ? gravatarUrl(user?.email, { default: '404' })
        : null,
    [isSkeleton, user?.email]
  );

  const initials = useMemo(
    () =>
      user?.name
        ?.split(' ')
        .filter((p) => p.length > 0)
        .map((p) => p[0])
        .join('') ?? '?',
    [user?.name]
  );

  return (
    <Avatar
      style={{
        backgroundColor: userColor.backgroundColor,
        color: userColor.color,
      }}
      alt={user?.name}
      title={user?.name}
      src={url}
      classes={{ img: classes.image }}
      className={classNames({ [classes.smallAvatar]: small })}
    >
      {!isSkeleton && initials}
    </Avatar>
  );
};

export default UserAvatar;
