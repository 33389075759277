import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  Collapse,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const styles = (theme) => ({
  active: {
    backgroundColor: '#EEE',
  },
  inset: {
    marginLeft: 30,
  },
});

function MenuItem({
  name,
  icon,
  subRoutes,
  inset,
  closeDrawer,
  classes,
  ...props
}) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <ListItem
        button
        component={NavLink}
        {...props}
        activeClassName={classes.active}
        onClick={() => {
          if (subRoutes) {
            setCollapsed(!collapsed);
          } else {
            closeDrawer?.();
          }
        }}
      >
        <ListItemIcon className={inset ? classes.inset : ''}>
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText inset primary={name} />
        {subRoutes && (collapsed ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {subRoutes && (
        <Collapse in={collapsed} timeout='auto'>
          <List component='div' disablePadding>
            {subRoutes.map((r) => (
              <ListItem
                button
                component={NavLink}
                {...props}
                activeClassName={classes.active}
                onClick={() => {
                  closeDrawer?.();
                }}
              >
                <ListItemIcon>
                  <Icon>{r.icon}</Icon>
                </ListItemIcon>
                <ListItemText inset primary={r.name} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default withStyles(styles)(MenuItem);
