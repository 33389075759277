import {
  InputAdornment,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { Cancel, Search } from '@material-ui/icons';

const styles = (theme) => ({
  headerComponent: { flex: 'auto' },
  searchField: {
    display: 'flex',
  },
  searchAdornment: {
    color: theme.palette.text.disabled,
    cursor: 'pointer',
  },
});

const SearchField = ({
  searchTerm,
  onChange,
  onClear,
  alignSelf,
  classes,
  alwaysShowClear = false,
}) => (
  <div
    className={classes.searchField}
    style={{ alignSelf: alignSelf || 'auto' }}
  >
    <TextField
      className={classes.headerComponent}
      value={searchTerm}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => {
        if (e.keyCode === 27) {
          onClear();
        }
      }}
      placeholder='Sök'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search className={classes.searchAdornment} />
          </InputAdornment>
        ),
        endAdornment:
          (searchTerm || alwaysShowClear) &&
          (alwaysShowClear ? (
            <Tooltip title='Rensa sortering'>
              <InputAdornment position='end'>
                <Cancel className={classes.searchAdornment} onClick={onClear} />
              </InputAdornment>
            </Tooltip>
          ) : (
            <InputAdornment position='end'>
              <Cancel className={classes.searchAdornment} onClick={onClear} />
            </InputAdornment>
          )),
      }}
    />
  </div>
);

export default withStyles(styles)(SearchField);
