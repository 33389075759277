import styled from '@emotion/styled';
import { min as minDates, parseISO } from 'date-fns';
import { produce } from 'immer';
import React, {
  ChangeEvent,
  VFC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { ReactSetter } from '../../../common/react-util';
import { EpochDate, notEmpty } from '../../../common/util';
import { CustomMapControl } from '../../../components/map/map-custom-control';
import { getTypeIcon } from './icon-common';
import { MapFilter, MeasuringPointExt, isMeasuringPointActive } from './model';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '30%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    heading: {
      flexBasis: '15%',
      flexShrink: 0,
    },
    accordion: { width: 280 },
  })
);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(255, 255, 255)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
  expanded: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 1)',
    overflowY: 'auto',
    // Calculate height so we get a 10px margin at the bottom.
    maxHeight: `calc(100vh - 194px);`,
    display: 'block',
  },
}))(MuiAccordionDetails);

const FlexDiv = styled.div<{ gap?: number }>(({ gap }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: gap,
}));

type PanelType = 'latest' | 'filter';

interface LeftPaneControlProps {
  measuringPoints: MeasuringPointExt[];
  measurePointsLoading: boolean;
  clusterMarkers: boolean;
  setClusterMarkers: ReactSetter<boolean>;
  mapFilter: MapFilter;
  setMapFilter: ReactSetter<MapFilter>;
  showMeasuringPoint: (id: number) => void;
  blastsCount: number;
  blastsLoading: boolean;
}

export const LeftPaneControl: VFC<LeftPaneControlProps> = ({
  measuringPoints,
  measurePointsLoading,
  clusterMarkers,
  setClusterMarkers,
  mapFilter,
  setMapFilter,
  showMeasuringPoint,
  blastsCount,
  blastsLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [expandedPanel, setExpandedPanel] = useState<PanelType | undefined>(
    window.screen.width > 1024 ? 'latest' : undefined
  );

  const [filterDate, setFilterDate] = useState(() => ({
    from: mapFilter.from,
    to: mapFilter.to,
  }));

  const earliestValidDate = useMemo(() => {
    const dates = measuringPoints
      .flatMap((mp) => mp.cards)
      .map((x) => x.validFrom)
      .filter(notEmpty)
      .map((x) => parseISO(x));

    if (dates.length === 0) {
      return EpochDate;
    }

    return minDates(dates);
  }, [measuringPoints]);

  useEffect(() => {
    if (measuringPoints.length > 0) {
      setFilterDate({ from: earliestValidDate, to: mapFilter.to });
    }
  }, [earliestValidDate, mapFilter.to, measuringPoints]);

  const handlePanelExpanded = useCallback(
    (panel: PanelType, expanded: boolean) => {
      setExpandedPanel(expanded ? panel : undefined);

      if (panel === 'latest') {
        setMapFilter(
          produce((draft) => {
            draft.from = EpochDate;
          })
        );
      }

      if (panel === 'filter') {
        setMapFilter(
          produce((draft) => {
            draft.from = filterDate.from;
          })
        );
      }
    },
    [filterDate.from, setMapFilter]
  );

  const handleFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setMapFilter(
        produce((draft) => {
          const filterIndex = draft.types.findIndex(
            (x) => x.name === e.target.name
          );

          if (filterIndex < 0) {
            return;
          }

          draft.types[filterIndex].show = checked;
        })
      );
    },
    [setMapFilter]
  );

  const filteredMPs = useMemo(() => {
    const activePrefixes = mapFilter.types
      .filter((x) => x.show)
      .map((x) => x.prefix);

    return measuringPoints.filter((mp) =>
      mp.channels.some((channel) =>
        activePrefixes.some(
          (prefix) => channel.type?.startsWith(prefix) ?? false
        )
      )
    );
  }, [mapFilter.types, measuringPoints]);

  const mpActiveNumber = useMemo(() => {
    return filteredMPs.filter((m) => isMeasuringPointActive(m)).length;
  }, [filteredMPs]);

  const mpInactiveNumber = useMemo(
    () => filteredMPs.length - mpActiveNumber,
    [filteredMPs.length, mpActiveNumber]
  );

  return (
    <CustomMapControl position='topleft' disableScrollPropagation>
      <div className={classes.accordion}>
        <Accordion
          expanded={expandedPanel === 'latest'}
          onChange={(_, expanded) => handlePanelExpanded('latest', expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Senaste</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FlexDiv>
              <FormControlLabel
                control={
                  <Checkbox
                    name='measuringPointActive'
                    checked={mapFilter.showActive}
                    color='primary'
                    disabled={measurePointsLoading}
                    onChange={() => {
                      setMapFilter(
                        produce((draft) => {
                          draft.showActive = !draft.showActive;
                        })
                      );
                    }}
                  />
                }
                label={
                  measurePointsLoading
                    ? 'Aktiva'
                    : `Aktiva (${mpActiveNumber} st)`
                }
              />
              {measurePointsLoading && <CircularProgress size={24} />}
            </FlexDiv>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name='mpInActive'
                    checked={mapFilter.showInactive}
                    color='primary'
                    disabled={measurePointsLoading}
                    onChange={() => {
                      setMapFilter(
                        produce((draft) => {
                          draft.showInactive = !draft.showInactive;
                        })
                      );
                    }}
                  />
                }
                label={
                  measurePointsLoading
                    ? 'Inaktiva'
                    : `Inaktiva (${mpInactiveNumber} st)`
                }
              />
            </div>
            <hr />
            <FlexDiv>
              <FormControlLabel
                control={
                  <Checkbox
                    name='markerCluster'
                    checked={mapFilter.showBlasts}
                    color='primary'
                    disabled={blastsLoading}
                    onChange={() => {
                      setMapFilter(
                        produce((draft) => {
                          draft.showBlasts = !draft.showBlasts;
                        })
                      );
                    }}
                  />
                }
                label={blastsLoading ? 'Salvor' : `Salvor (${blastsCount} st) `}
              />
              {blastsLoading && <CircularProgress size={24} />}
            </FlexDiv>
            <hr />
            {mapFilter.types.map((typeFilter) => (
              <div key={typeFilter.prefix}>
                <FormControlLabel
                  style={{ marginTop: 0, padding: 0 }}
                  control={
                    <Checkbox
                      checked={typeFilter.show}
                      name={typeFilter.name}
                      color='primary'
                      onChange={handleFilterChange}
                      disabled={measurePointsLoading}
                    />
                  }
                  label={
                    <FlexDiv gap={theme.spacing(1)}>
                      {getTypeIcon(typeFilter.prefix, 20)}
                      {typeFilter.name}
                    </FlexDiv>
                  }
                />
              </div>
            ))}
            <hr />
            <FormControlLabel
              control={
                <Checkbox
                  name='markerCluster'
                  checked={clusterMarkers}
                  color='primary'
                  onChange={() => {
                    setClusterMarkers((x) => !x);
                  }}
                />
              }
              label='Gruppera data'
            />
            <hr />
            {filteredMPs.map((m) => (
              <MeasuringPointButton
                key={m.id}
                measuringPoint={m}
                showInactive={mapFilter.showInactive}
                showMeasuringPoint={showMeasuringPoint}
              />
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedPanel === 'filter'}
          onChange={(_, expanded) => handlePanelExpanded('filter', expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Filter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KeyboardDatePicker
              variant='inline'
              label='Från'
              value={filterDate.from}
              onChange={(value) => {
                if (value == null) {
                  console.warn('invalid from date');
                  return;
                }

                setFilterDate(
                  produce((draft) => {
                    draft.from = value;
                  })
                );
                setMapFilter(
                  produce((draft) => {
                    draft.from = value;
                  })
                );
              }}
              format='yyyy-MM-dd'
              invalidDateMessage='Ogiltigt datumformat'
              disableFuture
              allowKeyboardControl
            />

            <KeyboardDatePicker
              variant='inline'
              label='Till'
              value={filterDate.to}
              onChange={(value) => {
                if (value == null) {
                  console.warn('invalid to date');
                  return;
                }

                setFilterDate(
                  produce((draft) => {
                    draft.to = value;
                  })
                );
                setMapFilter(
                  produce((draft) => {
                    draft.to = value;
                  })
                );
              }}
              format='yyyy-MM-dd'
              invalidDateMessage='Ogiltigt datumformat'
              disableFuture
              allowKeyboardControl
            />
            <Button
              style={{ width: '100%', textAlign: 'right', marginTop: 5 }}
              variant='outlined'
              onClick={() => {
                setFilterDate({
                  from: earliestValidDate ?? EpochDate,
                  to: new Date(),
                });
              }}
            >
              Återställ
            </Button>
            <hr />
            {measuringPoints.map((m) => (
              <MeasuringPointButton
                key={m.id}
                measuringPoint={m}
                showInactive={mapFilter.showInactive}
                showMeasuringPoint={showMeasuringPoint}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
    </CustomMapControl>
  );
};

const MeasuringPointButton: VFC<{
  measuringPoint: MeasuringPointExt;
  showInactive: boolean;
  showMeasuringPoint: (measuringPoint: number) => void;
}> = ({ measuringPoint, showInactive, showMeasuringPoint }) => {
  const theme = useTheme();

  return (
    <Button
      style={{
        display: 'block',
        textAlign: 'left',
        textTransform: 'none',
      }}
      disabled={!showInactive && !isMeasuringPointActive(measuringPoint)}
      onClick={() => {
        showMeasuringPoint(measuringPoint.id);
      }}
    >
      <FlexDiv gap={theme.spacing(1)}>
        <Typography>MP {measuringPoint.number}</Typography>
        {getTypeIcon(measuringPoint.type, 20)}
      </FlexDiv>
      <Typography>{measuringPoint.address}</Typography>
    </Button>
  );
};
