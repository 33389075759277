function notify(message, body, icon) {
  if (!('Notification' in window)) {
    return console.log(
      'Notify: This browser does not support system notifications'
    );
  }

  if (Notification.permission === 'granted') {
    createNotification(message, body, icon);

    return;
  }

  if (Notification.permission !== 'denied') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        createNotification(message, body, icon);
      }
    });
  }
}

function createNotification(message, body, icon) {
  const options = {
    body,
  };

  if (icon) {
    options.icon = `/images/notification-${icon}.png`;
  }

  new Notification(message, options);
}

export { notify };
