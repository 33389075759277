import { useState } from 'react';

import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core';

export function AppSwitcher() {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <IconButton
        aria-owns={open ? 'menu-apps' : null}
        aria-haspopup='true'
        onClick={(e) => {
          setOpen(!open);
          setAnchorEl(e.currentTarget);
        }}
        color='inherit'
      >
        <Icon fontSize='medium' style={{ color: '#f9f9f9' }}>
          {'apps'}
        </Icon>
      </IconButton>
      <Menu
        id='menu-apps'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => {
          setOpen(false);
          setAnchorEl(null);
        }}
      >
        <MenuItem
          component='a'
          onClick={() => {
            setOpen(false);
            setAnchorEl(null);
          }}
          href={process.env.REACT_APP_QBIS_URI}
          target='_blank'
        >
          QBIS
        </MenuItem>
        <MenuItem
          component='a'
          onClick={() => {
            setOpen(false);
            setAnchorEl(null);
          }}
          href={process.env.REACT_APP_TELLUS_SKETCH_URI}
          target='_blank'
        >
          TellUs Sketch
        </MenuItem>
        <MenuItem
          component='a'
          onClick={() => {
            setOpen(false);
            setAnchorEl(null);
          }}
          href={process.env.REACT_APP_TELLUS_SKETCH_OLD_URI}
          target='_blank'
        >
          TellUs Sketch(gamla)
        </MenuItem>
      </Menu>
    </>
  );
}

export default AppSwitcher;
