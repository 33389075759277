import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerText: {
    color: theme.palette.text.hint,
  },
  img: {
    width: '12em',
    height: '10em',
    marginTop: 20,
  },
});

function NotFound({ classes }) {
  return (
    <div className={classes.container}>
      <Typography variant='h6' color='textSecondary' gutterBottom>
        Ojdå! Här var det tomt
      </Typography>
      <Typography className={classes.containerText}>
        Sidan du försökte nå finns inte
      </Typography>

      <img
        className={classes.img}
        src='/images/notfound.svg'
        alt='Här var det tomt'
      />
    </div>
  );
}

export default withStyles(styles)(NotFound);
