import {
  LayerProps,
  createTileLayerComponent,
  withPane,
} from '@react-leaflet/core';
import { Coords, DoneCallback, TileLayer, TileLayerOptions } from 'leaflet';
import React from 'react';

import { useRequiredContext } from '../../common/hooks-util';
import { AuthContext } from '../../contexts/auth-context';

export const LantmaterietTileLayer: React.VFC = () => {
  const { getAccessToken } = useRequiredContext(AuthContext);

  return <LantmaterietTileLayerImpl getAccessToken={getAccessToken} />;
};

export interface LantmaterietTileLayerImplProps
  extends Omit<TileLayerOptions, 'accessToken'>,
    LayerProps {
  url?: string;
  getAccessToken: () => Promise<string | undefined>;
}

const LantmaterietTileLayerImpl = createTileLayerComponent<
  TileLayerImpl,
  LantmaterietTileLayerImplProps
>(({ url, ...options }, context) => ({
  instance: new TileLayerImpl(url ?? '', withPane(options, context)),
  context,
}));
LantmaterietTileLayerImpl.defaultProps = {
  url: `${process.env.REACT_APP_API_URI}Map/Tile/3006/{z}/{y}/{x}`,
  attribution: '&copy; <a href="https://lantmateriet.se/">Lantmäteriet</a>',
  maxNativeZoom: 13,
};

class TileLayerImpl extends TileLayer {
  private getAccessToken: () => Promise<string | undefined>;

  public constructor(
    urlTemplate: string,
    options: LantmaterietTileLayerImplProps
  ) {
    super(urlTemplate, options);
    this.getAccessToken = options.getAccessToken;
  }

  protected createTile(coords: Coords, done: DoneCallback): HTMLElement {
    const tile = document.createElement('img');

    const url = new URL(this.getTileUrl(coords), window.location.origin);

    this.getAccessToken().then((token) => {
      if (token != null) {
        url.searchParams.set('access_token', token);
      }
      tile.src = url.toString();
      tile.onload = () => done(undefined, tile);
    });

    return tile;
  }
}
