import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  img: {
    width: '12em',
    height: '10em',
    marginTop: 20,
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  emptyText: {
    color: theme.palette.text.hint,
  },
  link: {
    color: theme.palette.secondary.main,
  },
});

const EmptyListDrawing = ({
  title,
  entity,
  text,
  addHandlers,
  svgPath,
  classes,
}) => {
  return (
    <div className={classes.emptyContainer}>
      <div className={classes.empty}>
        <Typography variant='h6' color='textSecondary' gutterBottom>
          {title}
        </Typography>
        {entity && addHandlers ? (
          <Typography className={classes.emptyText}>
            Klicka{' '}
            <a
              href='#dialog'
              onClick={(e) => {
                e.preventDefault();
                addHandlers();
              }}
              className={classes.link}
            >
              här
            </a>{' '}
            för att lägga till {entity}
          </Typography>
        ) : Array.isArray(text) ? (
          text.map((t, i) => (
            <Typography className={classes.emptyText} key={i}>
              {t}
            </Typography>
          ))
        ) : (
          <Typography className={classes.emptyText}>{text}</Typography>
        )}
        {svgPath ? (
          <img
            className={classes.img}
            src={`/images/${svgPath}`}
            alt={svgPath.split('.')[0]}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(EmptyListDrawing);
