import proj4 from 'proj4';

export const sweref = function (name, epsg, def) {
  return {
    name: 'SWEREF99 ' + name + ' (EPSG:' + epsg + ')',
    axis: 'xy',
    axisNames: ['X', 'Y'],
    def: def,
  };
};

export function handleCoord(x) {
  return Number(typeof x !== 'string' ? x : x.replace(',', '.'));
}

export const inverse = function (selectedCrs, long, lat) {
  return getProjection(selectedCrs).inverse([
    handleCoord(long),
    handleCoord(lat),
  ]);
};

export const forward = function (selectedCrs, long, lat) {
  return getProjection(selectedCrs).forward([
    handleCoord(long),
    handleCoord(lat),
  ]);
};

export const getCrsDefinition = function (selectedCrs) {
  return crsValues[selectedCrs].def;
};

const getProjection = function (selectedCrs) {
  return proj4(getCrsDefinition(selectedCrs));
};

export const crsValues = {
  4326: {
    name: 'WGS84 / GPS (latitud/longitud)',
    axisNames: ['Latitud', 'Longitud'],
    axis: 'yx',
    def: '+proj=longlat +datum=WGS84 +no_defs ',
  },
  3006: sweref(
    'TM',
    3006,
    '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3007: sweref(
    '12 00',
    3007,
    '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  ),
  3008: sweref(
    '13 30',
    3008,
    '+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3009: sweref(
    '15 00',
    3009,
    '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3010: sweref(
    '16 30',
    3010,
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3011: sweref(
    '18 00',
    3011,
    '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3012: sweref(
    '14 15',
    3012,
    '+proj=tmerc +lat_0=0 +lon_0=14.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3013: sweref(
    '15 45',
    3013,
    '+proj=tmerc +lat_0=0 +lon_0=15.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3014: sweref(
    '17 15',
    3014,
    '+proj=tmerc +lat_0=0 +lon_0=17.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3015: sweref(
    '18 45',
    3015,
    '+proj=tmerc +lat_0=0 +lon_0=18.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3016: sweref(
    '20 15',
    3016,
    '+proj=tmerc +lat_0=0 +lon_0=20.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3017: sweref(
    '21 45',
    3017,
    '+proj=tmerc +lat_0=0 +lon_0=21.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
  3018: sweref(
    '23 15',
    3018,
    '+proj=tmerc +lat_0=0 +lon_0=23.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
  ),
};
