import { gql } from '@apollo/client';
import {
  differenceInMonths,
  format,
  formatDistance,
  isAfter,
  isWithinInterval,
  parseISO,
} from 'date-fns';
import { sv } from 'date-fns/locale';
import Skeleton from 'react-loading-skeleton';

import {
  Icon,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  lastStatusActivityWarning,
  minBatteryWarning,
  minCapacityWarning,
} from '../../../common/instrument-notifications';
import QueryWrapper from '../../../components/query-wrapper';

export const GET_MEASURING_CARDS_AND_POINTS = gql`
  query Project($projectReference: String) {
    project(referenceNumber: $projectReference) {
      id
      startDate
      endDate
      projectUsers {
        notificationFlag
        notificationPoints
        notification
        type
        email
        userId
      }
      measuringCards {
        id
        instrument {
          id
          name
          model
        }
        instrumentBattery
        instrumentCapacity
        lastTriggerActivity
        lastStatusActivity
        validSetup
        validFrom
        validTo
        unMount
        allChannelsOK
        channels {
          id
          measuringPointId
        }
      }
      measuringPoints {
        id
        number
        address
        fixedBenchmark
        noBenchmark
        unknownBenchmark
      }
    }
  }
`;

const ProjectNotificationsWidget = ({ classes, referenceNumber }) => {
  return (
    <Paper className={classes.widget}>
      <QueryWrapper
        query={GET_MEASURING_CARDS_AND_POINTS}
        variables={{
          projectReference: referenceNumber,
        }}
        errorMessageProps={{ gutter: true }}
      >
        {({ loading, error, data }) => {
          if (error) {
            console.warn(error);
            return null;
          }

          let notifications = [];
          if (!loading) {
            if (data && data.project && data.project.measuringCards) {
              const now = new Date();
              data = {
                ...data,
                project: {
                  ...data.project,
                  measuringCards: data.project.measuringCards.map((c) => ({
                    ...c,
                    isDormant: !isWithinInterval(now, {
                      start: new Date(c.validFrom),
                      end: new Date(c.validTo),
                    }),
                  })),
                },
              };
            }
            notifications =
              data && data.project && data.project.projectUsers
                ? aggregateNotifications(data)
                : [];
          }

          return loading || notifications.length > 0 ? (
            <List className={classes.list}>
              {loading
                ? Array.from(Array(4)).map((_, i) => (
                    <ListItem divider key={i}>
                      <ListItemText
                        primary={<Skeleton width={150} />}
                        secondary={<Skeleton width={200} />}
                      />
                    </ListItem>
                  ))
                : notifications.map((notification) => (
                    <ListItem divider key={notification.title}>
                      <ListItemText
                        primary={
                          <Typography
                            style={{ fontSize: '0.9rem', lineHeight: '1.3' }}
                          >
                            {notification.title}
                          </Typography>
                        }
                        secondaryTypographyProps={{ component: 'div' }}
                        style={{ fontSize: '0.9rem', lineHeight: '1' }}
                        secondary={
                          <>
                            {notification.messages.map((m, i) => (
                              <div
                                key={i}
                                className={classes.iconRow}
                                style={{ fontSize: '0.8rem', lineHeight: '1' }}
                              >
                                <Icon
                                  style={{
                                    position: 'relative',
                                    top: '-2px',
                                    verticalAlign: 'middle',
                                    marginRight: '2px',
                                    width: '20px',
                                    height: '20px',
                                    fontSize: '20px',
                                  }}
                                  className={
                                    classes[notification.priorities[i]]
                                  }
                                >
                                  {notification.icons[i]}
                                </Icon>{' '}
                                {m}
                              </div>
                            ))}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
            </List>
          ) : (
            <Typography
              variant='h6'
              color='textPrimary'
              className={classes.emptyWidgetInfo}
            >
              <span role='img' aria-label='Tada!'>
                🎉
              </span>{' '}
              Inga varningar för tillfället, bra jobbat!
            </Typography>
          );
        }}
      </QueryWrapper>
    </Paper>
  );
};

const measuringCardNotificationCritierias = [
  {
    test: (mc, p) => {
      if (mc.isDormant) return false;
      if (!mc.channels || !mc.channels.length) return true;

      let pointIds = mc.channels
        .filter((c) => !!c.measuringPointId)
        .map((c) => c.measuringPointId);

      if (!pointIds.length) return true;

      let userWithAllNotifications = p.projectUsers.find(
        (pu) =>
          (pu.notificationFlag === 1 || pu.notificationFlag === 3) &&
          !pu.notificationPoints
      );

      if (userWithAllNotifications) return false;

      let mpHasNotification = pointIds
        .map((pId) => {
          let mp = p.measuringPoints.find((mp) => mp.id === pId);
          return mp ? mp.number : null;
        })
        .find(
          (mpn) =>
            !!p.projectUsers
              .filter((pu) => pu.notificationFlag !== 0)
              .find(
                (pu) =>
                  pu.notificationPoints &&
                  pu.notificationPoints
                    .split(' ')
                    .map((x) => x.replace(',', '.'))
                    .map(parseFloat)
                    .includes(mpn)
              )
        );

      return !mpHasNotification;
    },
    title: (mc, p) => {
      if (mc.channels && mc.channels.length) {
        let measuringPointIds = mc.channels
          .filter((x) => x.measuringPointId)
          .map((x) => x.measuringPointId);
        if (measuringPointIds.length) {
          if (measuringPointIds.length === 1) {
            const point = p.measuringPoints.find(
              (x) => x.id === measuringPointIds[0]
            );
            const number = point && point.number ? point.number : '';
            return `MP ${number}`;
          } else {
            return `MP ${p.measuringPoints
              .filter((x) => measuringPointIds.includes(x.id))
              .map((m) => m.number)
              .join(' och/eller ')}`;
          }
        }
      }

      return `Instrument ${mc.instrument.name}`;
    },
    message: () => `Saknar larmmottagare`,
    icon: 'mobile_off',
    priority: 1,
  },
  {
    test: (mc) => !mc.isDormant && !mc.lastStatusActivity,
    title: (mc, p) => {
      if (mc.channels && mc.channels.length) {
        let measuringPointIds = mc.channels
          .filter((x) => x.measuringPointId)
          .map((x) => x.measuringPointId);
        if (measuringPointIds.length) {
          if (measuringPointIds.length === 1) {
            const point = p.measuringPoints.find(
              (x) => x.id === measuringPointIds[0]
            );
            const number = point && point.number ? point.number : '';
            return `MP ${number}`;
          } else {
            return `MP ${p.measuringPoints
              .filter((x) => measuringPointIds.includes(x.id))
              .map((m) => m.number)
              .join(' och/eller ')}`;
          }
        }
      }

      return `Instrument ${mc.instrument.name}`;
    },
    message: () => `Saknar statusrapport`,
    icon: 'error_outline',
    priority: 1,
  },
  {
    test: (mc) => !mc.isDormant && !mc.lastTriggerActivity,
    title: (mc, p) => {
      if (mc.channels && mc.channels.length) {
        let measuringPointIds = mc.channels
          .filter((x) => x.measuringPointId)
          .map((x) => x.measuringPointId);
        if (measuringPointIds.length) {
          if (measuringPointIds.length === 1) {
            const point = p.measuringPoints.find(
              (x) => x.id === measuringPointIds[0]
            );
            const number = point && point.number ? point.number : '';
            return `MP ${number}`;
          } else {
            return `MP ${p.measuringPoints
              .filter((x) => measuringPointIds.includes(x.id))
              .map((m) => m.number)
              .join(' och/eller ')}`;
          }
        }
      }
      return `Instrument ${mc.instrument.name}`;
    },
    message: () => `Saknar mätdata`,
    icon: 'signal_cellular_connected_no_internet_4_bar',
    priority: 2,
  },
  {
    test: (mc) =>
      !mc.isDormant &&
      mc.lastStatusActivity != null &&
      lastStatusActivityWarning(mc.lastStatusActivity),
    title: (mc, p) => {
      if (mc.channels && mc.channels.length) {
        let measuringPointIds = mc.channels
          .filter((x) => x.measuringPointId)
          .map((x) => x.measuringPointId);
        if (measuringPointIds.length) {
          if (measuringPointIds.length === 1) {
            const point = p.measuringPoints.find(
              (x) => x.id === measuringPointIds[0]
            );
            const number = point && point.number ? point.number : '';
            return `MP ${number}`;
          } else {
            return `MP ${p.measuringPoints
              .filter((x) => measuringPointIds.includes(x.id))
              .map((m) => m.number)
              .join(' och/eller ')}`;
          }
        }
      }
      return `Instrument ${mc.instrument.name}`;
    },
    message: (mc) => (
      <Tooltip
        title={format(parseISO(mc.lastStatusActivity), 'yyyy-MM-dd HH:mm')}
      >
        <span>
          Rapporterade status för{' '}
          {formatDistance(new Date(), parseISO(mc.lastStatusActivity), {
            locale: sv,
          })}{' '}
          sedan
        </span>
      </Tooltip>
    ),
    icon: 'signal_cellular_connected_no_internet_4_bar',
    priority: 3,
  },
  {
    test: (mc) => !mc.isDormant && !mc.validSetup,
    title: (mc, p) => {
      if (mc.channels && mc.channels.length) {
        let measuringPointIds = mc.channels
          .filter((x) => x.measuringPointId)
          .map((x) => x.measuringPointId);
        if (measuringPointIds.length) {
          if (measuringPointIds.length === 1) {
            const point = p.measuringPoints.find(
              (x) => x.id === measuringPointIds[0]
            );
            const number = point && point.number ? point.number : '';
            return `MP ${number}`;
          } else {
            return `MP ${p.measuringPoints
              .filter((x) => measuringPointIds.includes(x.id))
              .map((m) => m.number)
              .join(' och/eller ')}`;
          }
        }
      }
      return `Instrument ${mc.instrument.name}`;
    },
    message: () => `Felaktiga kanalinställningar`,
    icon: 'error_outline',
    priority: 3,
  },
  {
    test: (mc) => !mc.isDormant && !mc.allChannelsOK,
    title: (mc, p) => {
      if (mc.channels && mc.channels.length) {
        let measuringPointIds = mc.channels
          .filter((x) => x.measuringPointId)
          .map((x) => x.measuringPointId);
        if (measuringPointIds.length) {
          if (measuringPointIds.length === 1) {
            const point = p.measuringPoints.find(
              (x) => x.id === measuringPointIds[0]
            );
            const number = point && point.number ? point.number : '';
            return `MP ${number}`;
          } else {
            return `MP ${p.measuringPoints
              .filter((x) => measuringPointIds.includes(x.id))
              .map((m) => m.number)
              .join(' och/eller ')}`;
          }
        }
      }
      return `Instrument ${mc.instrument.name}`;
    },
    message: () => `Inrapporterat kanalfel`,
    icon: 'error_outline',
    priority: 3,
  },
  {
    test: (mc) =>
      !mc.isDormant &&
      minBatteryWarning(mc.instrument.model, mc.instrumentBattery),
    title: (mc, p) => {
      if (mc.channels && mc.channels.length) {
        let measuringPointIds = mc.channels
          .filter((x) => x.measuringPointId)
          .map((x) => x.measuringPointId);
        if (measuringPointIds.length) {
          if (measuringPointIds.length === 1) {
            const point = p.measuringPoints.find(
              (x) => x.id === measuringPointIds[0]
            );
            const number = point && point.number ? point.number : '';
            return `MP ${number}`;
          } else {
            return `MP ${p.measuringPoints
              .filter((x) => measuringPointIds.includes(x.id))
              .map((m) => m.number)
              .join(' och/eller ')}`;
          }
        }
      }
      return `Instrument ${mc.instrument.name}`;
    },
    message: (mc) =>
      `Låg batterispänning, ${mc.instrumentBattery.toFixed(1)} V`,
    icon: 'battery_alert',
    priority: 2,
  },
  {
    test: (mc) =>
      !mc.isDormant &&
      minCapacityWarning(mc.instrument.model, mc.instrumentCapacity),
    title: (mc, p) => {
      if (mc.channels && mc.channels.length) {
        let measuringPointIds = mc.channels
          .filter((x) => x.measuringPointId)
          .map((x) => x.measuringPointId);
        if (measuringPointIds.length) {
          if (measuringPointIds.length === 1) {
            const point = p.measuringPoints.find(
              (x) => x.id === measuringPointIds[0]
            );
            const number = point && point.number ? point.number : '';
            return `MP ${number}`;
          } else {
            return `MP ${p.measuringPoints
              .filter((x) => measuringPointIds.includes(x.id))
              .map((m) => m.number)
              .join(' och/eller ')}`;
          }
        }
      }
      return `Instrument ${mc.instrument.name}`;
    },
    message: (mc) =>
      `Minnet snart fullt, ${Math.round(mc.instrumentCapacity)} %`,
    icon: 'sd_card',
    priority: 2,
  },
];

const projectNotificationCriterias = [
  {
    test: (p) => isAfter(new Date(), parseISO(p.endDate)),
    title: () => `Projektet avslutat`,
    message: (p) => `Avslutades ${format(new Date(p.endDate), 'yyyy-MM-dd')}`,
    icon: 'snooze',
    priority: 1,
  },
  {
    test: (p) =>
      !isAfter(new Date(), parseISO(p.endDate)) &&
      differenceInMonths(parseISO(p.endDate), new Date()) < 1,
    title: () => `Projekttiden går snart ut`,
    message: (p) => `Avslutas ${format(new Date(p.endDate), 'yyyy-MM-dd')}`,
    icon: 'calendar_today',
    priority: 2,
  },
];

const measuringPointNotificationCriterias = [
  {
    test: (mp) => !!mp.unknownBenchmark,
    title: (mp) => `MP ${mp.number}`,
    message: () => `Saknar riktvärde`,
    icon: 'show_chart',
    priority: 1,
  },
];

const group = (xs) => {
  const groupHash = xs.reduce((a, x) => {
    const key = x.title;
    let values = a[key];
    if (!values) {
      values = a[key] = {
        messages: [],
        icons: [],
        priorities: [],
      };
    }
    values.messages.push(x.message);
    values.icons.push(x.icon);
    values.priorities.push(x.priority);
    return a;
  }, {});

  return Object.keys(groupHash)
    .map((title) => ({
      title,
      messages: groupHash[title].messages,
      icons: groupHash[title].icons,
      priorities: groupHash[title].priorities,
    }))
    .sort((a, b) =>
      a.priorities[0] > b.priorities[0]
        ? 1
        : a.priorities[0] < b.priorities[0]
        ? -1
        : 0
    );
};

const flatten = (xs) => Array.prototype.concat.apply([], xs);
const applyCriteriasToOne = (criterias, context, x) =>
  criterias
    .filter((criteria) => criteria.test(x, context))
    .map((criteria) => ({
      title: criteria.title(x, context),
      message: criteria.message(x, context),
      icon: criteria.icon,
      priority: criteria.priority,
    }));

const applyCriterias = (criterias, xs, context) =>
  flatten(xs.map(applyCriteriasToOne.bind(null, criterias, context)));

export const aggregateNotifications = (data) => {
  return group(
    flatten([
      applyCriteriasToOne(
        projectNotificationCriterias,
        null /** Does not need project context */,
        data.project
      ),
      applyCriterias(
        measuringCardNotificationCritierias,
        data.project.measuringCards.filter((x) => !x.unMount),
        data.project
      ),
      applyCriterias(
        measuringPointNotificationCriterias,
        data.project.measuringPoints,
        data.project
      ),
    ])
  );
};

export default ProjectNotificationsWidget;
