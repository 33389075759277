import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { AttributionControl, MapContainer } from 'react-leaflet';
import { MapContainerProps } from 'react-leaflet/types/MapContainer';

import { styled } from '@material-ui/core/styles';

import { LantmaterietTileLayer } from './lantmateriet-tile-layer';

L.Proj = require('proj4leaflet');

const resolutions = [
  4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1,
  /* These are virtual zooms, only for making map can be zoomed in more */
  0.5, 0.25, 0.125,
];

export const DefaultMapCenter = new L.LatLng(57.68927, 11.99532);
export const DefaultMapZoom = 11;
export const DefaultMapMaxZoom = resolutions.length - 1;
export const DefaultMapBoundsPadding: L.PointExpression = [30, 30];

const BaseMapContainer = styled(MapContainer)({
  height: '100%',
});

const crs = new L.Proj.CRS(
  'EPSG:3006',
  '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  {
    resolutions: resolutions,
    bounds: L.bounds([-1200000.0, 8500000.0], [4305696.0, 2994304.0]),
    origin: [-1200000.0, 8500000.0],
  }
);
export interface BaseMapProps extends MapContainerProps {}

export const BaseMap: React.FC<BaseMapProps> = ({ children, ...props }) => {
  return (
    <BaseMapContainer {...props}>
      <AttributionControl position='bottomright' prefix='' />
      <LantmaterietTileLayer />
      {children}
    </BaseMapContainer>
  );
};
BaseMap.defaultProps = {
  center: DefaultMapCenter,
  zoom: DefaultMapZoom,
  maxZoom: DefaultMapMaxZoom,
  attributionControl: false,
  crs: crs,
};
