import React, { useEffect, useMemo, useState } from 'react';

import { Slide } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification';

import { useServiceWorker } from '../common/service-worker-hook';

// Hide the update prompt for 30 minutes when dismissed.
const HIDE_UPDATE_PROMPT_TIMEOUT = 30 * 60 * 1000;

export const UpdateNotification: React.FC = () => {
  const serviceWorker = useServiceWorker();
  const [hideUpdatePrompt, setHideUpdatePrompt] = useState(false);

  useEffect(() => {
    if (!hideUpdatePrompt) {
      return;
    }

    const hideTimer = setTimeout(() => {
      setHideUpdatePrompt(false);
    }, HIDE_UPDATE_PROMPT_TIMEOUT);
    return () => {
      clearTimeout(hideTimer);
    };
  }, [hideUpdatePrompt]);

  const showUpdatePrompt = useMemo(
    () => !hideUpdatePrompt && serviceWorker.haveWaiting,
    [hideUpdatePrompt, serviceWorker.haveWaiting]
  );

  return (
    <NotificationGroup
      style={{
        left: 0,
        bottom: 0,
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
        margin: '4px 8px',
      }}
    >
      <Slide direction={showUpdatePrompt ? 'up' : 'down'}>
        {showUpdatePrompt && (
          <Notification
            type={{ style: 'info' }}
            style={{
              padding: '4px 0px 4px 16px',
            }}
          >
            Ny version tillgänglig.
            <Button
              style={{
                marginLeft: '8px',
                padding: '6px 12px',
              }}
              onClick={() => {
                serviceWorker.skipWaiting();
              }}
            >
              Uppdatera
            </Button>
            <Button
              style={{
                color: 'white',
              }}
              icon='close'
              look='clear'
              onClick={() => {
                setHideUpdatePrompt(true);
              }}
            />
          </Notification>
        )}
      </Slide>
    </NotificationGroup>
  );
};
UpdateNotification.displayName = 'UpdateNotification';
