import L, { LatLngExpression, LeafletMouseEvent } from 'leaflet';
import { FC, ReactNode } from 'react';
import { useMapEvents } from 'react-leaflet';

import {
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';

import DialogWrapper from '../dialog-wrapper';
import { BaseMap } from './base-map';

const useStyles = makeStyles(() => ({
  dialogPaper: { width: '100%' },
}));

interface FullMapWidgetProps {
  children?: ReactNode;
  title?: string;
  zoom?: number;
  showZoomControl?: boolean;
  center?: LatLngExpression;
  open: boolean;
  onClose: () => void;
  handleMapCreated?: (map: L.Map) => void;
  onClick?: (event: LeafletMouseEvent) => void;
}

export const FullMapWidget: FC<FullMapWidgetProps> = ({
  children,
  title,
  zoom,
  showZoomControl = true,
  center,
  open,
  onClose,
  handleMapCreated,
  onClick,
}) => {
  const classes = useStyles();

  const MapEvents = () => {
    useMapEvents({
      click: (e: LeafletMouseEvent) => {
        onClick?.(e);
      },
    });
    return null;
  };

  return (
    <DialogWrapper
      fullScreen
      open={open}
      onClose={onClose}
      paperClass={classes.dialogPaper}
    >
      <DialogTitle>
        <Typography>{title ?? 'Karta'}</Typography>
      </DialogTitle>

      <Divider />

      <BaseMap
        zoom={zoom}
        center={center}
        style={{ height: '100%' }}
        whenCreated={handleMapCreated}
        zoomControl={showZoomControl}
      >
        <MapEvents />
        {children}
      </BaseMap>
    </DialogWrapper>
  );
};
