import className from 'classnames';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
  },
  gutter: {
    padding: '24px',
  },
  img: {
    width: '6em',
    height: '5em',
    marginRight: '24px',
  },
});

const errorTitle = ({ message }) => {
  console.log('message', message);
  if (message.includes('You are not authorized to run this query.')) {
    return (
      <span>
        Ej behörig&nbsp;
        <span role='img' aria-label='Hm...'>
          ✋
        </span>
      </span>
    );
  }

  if (message.startsWith('Network error:')) {
    return (
      <span>
        Nätverksfel&nbsp;
        <span role='img' aria-label='Hm...'>
          🤔
        </span>
      </span>
    );
  }

  return (
    <span>
      Något har gått fel...&nbsp;
      <span role='img' aria-label='Besviken'>
        😞
      </span>
    </span>
  );
};

const Content = ({ classes, error, gutter }) => (
  <>
    <div className={className(classes.wrapper, gutter && classes.gutter) || ''}>
      <img
        className={classes.img}
        src='/images/bugfixing.svg'
        alt='Ett fel har inträffat'
      />
      <div>
        <Typography variant='h6' component='h6' color='textPrimary'>
          {errorTitle(error)}
        </Typography>
        <Typography color='textSecondary'>
          Försök igen, pröva på ett annat sätt, eller rapportera felet om det
          inte löser sig.
        </Typography>
      </div>
    </div>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='subtitle2' color='textSecondary'>
          Teknisk beskrivning
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <pre style={{ overflow: 'auto' }}>
          <code>{error.toString()}</code>
        </pre>
      </AccordionDetails>
    </Accordion>
  </>
);

const ErrorMessage = (props) =>
  props.paper ? (
    <Paper>
      <Content gutter {...props} />
    </Paper>
  ) : (
    <Content {...props} />
  );

export default withStyles(styles)(ErrorMessage);
