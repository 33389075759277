import { Mutation } from '@apollo/client/react/components';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';

import DialogWrapper from './dialog-wrapper';

const styles = (theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.error.main,
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  dialogTitleText: {
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  content: {
    padding: 20,
  },
});

const DeleteDialog = ({
  fullScreen,
  isModalOpen,
  handleModalClose,
  referenceNumber,
  classes,
  deleteInfo,
  data,
}) => {
  if (data === null) {
    return '';
  }

  const handleDelete = (deleteVariable) => {
    deleteVariable({
      variables: {
        id: data.id,
        isActive: false,
      },
    });
    handleModalClose(false);
  };

  return (
    <div>
      <DialogWrapper open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography
            className={classes.dialogTitleText}
            color='textSecondary'
            variant='h5'
          >
            <Warning />
            Borttagning
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText className={classes.content}>
            <Typography component='span' align='center' variant='body2'>
              Är du säker att du vill ta bort
              <strong> {data.name || data.address}</strong>?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <Mutation
          mutation={deleteInfo.deleteMutation}
          refetchQueries={() => [
            {
              query: deleteInfo.refetchQuery,
              variables: {
                referenceNumber,
              },
            },
          ]}
        >
          {(deleteVariable, { data, error }) => (
            <DialogActions>
              <Button onClick={handleModalClose}>Avbryt</Button>
              <Button
                onClick={() => handleDelete(deleteVariable)}
                className={classes.deleteButton}
                variant='contained'
              >
                Ja, ta bort
              </Button>
            </DialogActions>
          )}
        </Mutation>
      </DialogWrapper>
    </div>
  );
};

export default withStyles(styles)(DeleteDialog);
