import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import React, { useMemo } from 'react';

import { useRequiredContext } from './common/hooks-util';
import { AuthContext } from './contexts/auth-context';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const ApolloClientProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useRequiredContext(AuthContext);

  const client = useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessToken();
      if (token == null) {
        return headers;
      }

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    return new ApolloClient({
      link: from([errorLink, authLink.concat(httpLink)]),
      cache: new InMemoryCache(),
    });
  }, [getAccessToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
