import React from 'react';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 16,
      },
      gutters: {
        paddingLeft: 7,
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 6,
      },
    },
    MuiListItemText: {
      inset: { paddingLeft: 16 },
      multiline: { marginLeft: 10, marginTop: 4, marginBottom: 4 },
    },
    MuiListItemIcon: {
      root: { minWidth: 24 },
    },
    MuiGrid: {
      item: { padding: 0 },
      'spacing-xs-8': {
        item: { padding: 0 },
      },
    },
    MuiTableRow: {
      root: {
        height: '30px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '2px 8px',
        fontSize: '0.8125rem',
      },
    },
    MuiIconButton: {
      root: {
        padding: '3px',
      },
    },
    MuiCardContent: {
      root: {
        padding: 4,
      },
    },
    MuiCardHeader: {
      root: {
        padding: 4,
      },
    },
    MuiSnackbarContent: {
      message: {
        width: '100%',
      },
    },
    MuiPaper: {
      root: { margin: 0 },
    },
  },
  palette: {
    type: 'light',
    primary: {
      light: '#5180ca',
      main: '#0a5499',
      dark: '#002c6a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#c2f87b',
      main: '#8fc54b',
      dark: '#5e9417',
      contrastText: '#000',
    },
    warning: {
      light: '#f3ca80',
      main: '#e69500',
      dark: '#a16800',
      contrastText: '#000',
    },
    error: {
      light: 'rgba(226,19,4,0.08)',
      main: '#f44336',
      dark: 'rgba(226, 19, 4, 1)',
      contrastText: 'rgba(214,1,1,1)',
    },
    info: {
      light: 'rgba(83,182,255,0.15)',
      main: '#0a5499',
      dark: 'rgba(0,90,156,0.4)',
      contrastText: 'rgba(0,90,156,1)',
    },
    success: {
      light: 'rgba(0,185,0,0.06)',
      main: 'rgba(0,185,0,0.06)',
      dark: 'rgba(0,128,0,0.4)',
      contrastText: 'rgba(32,123,32,1)',
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontFamily: '"Archivo Narrow", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontFamily: '"Archivo Narrow", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontFamily: '"Archivo Narrow", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontFamily: '"Archivo Narrow", "Helvetica", "Arial", sans-serif',
    },
    h5: {
      fontFamily: '"Archivo Narrow", "Helvetica", "Arial", sans-serif',
    },
    h6: {
      fontFamily: '"Archivo Narrow", "Helvetica", "Arial", sans-serif',
    },
  },
});

export const MetronThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);
