import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInDays,
  parseISO,
} from 'date-fns';

export const minBatteryLevel = {
  FRED04: 9.5,
  FRED06: 3.7,
  FREDONE: 10.5,
  SIGICOM: 11.7,
  NEWSIGICOM: 13.8,
};

export const minBatteryWarning = (model, instrumentBattery) => {
  return (
    instrumentBattery !== null && instrumentBattery <= minBatteryLevel[model]
  );
};

/**
 * Check if the status date is withing the accepted time.
 * @param {string | date | null | undefined} lastStatusActivity
 * @returns {boolean}
 */
export const lastStatusActivityWarning = (lastStatusActivity) => {
  return (
    !lastStatusActivity ||
    differenceInDays(new Date(), parseISO(lastStatusActivity)) >= 1
  );
};

export const minCapacityWarning = (model, instrumentCapacity) => {
  if (instrumentCapacity === null) return;

  const threshold = model === 'SIGICOM' ? 50 : 25;

  return instrumentCapacity <= threshold;
};

export const calibrationDateWarning = (value) => {
  if (!value) return 3;
  const today = new Date();
  if (today >= value) return 3;

  const isoValue = parseISO(value);

  if (differenceInCalendarDays(isoValue, today) <= 14) return 2;
  if (differenceInCalendarMonths(isoValue, today) <= 2) return 1;
  return 0;
};
