import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';

import DialogWrapper from './dialog-wrapper';

const styles = (theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.error.main,
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  dialogTitleText: {
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: 20,
  },
});

const ErrorMessageDialog = ({
  classes,
  isModalOpen,
  handleModalClose,
  message,
}) => {
  if (!message) {
    return '';
  }

  return (
    <div>
      <DialogWrapper open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography
            className={classes.dialogTitleText}
            color='textSecondary'
            variant='h5'
          >
            <Warning />
            Fel
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText className={classes.content}>
            <Typography component='span' align='center' variant='body2'>
              <strong> {message}</strong>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>OK</Button>
        </DialogActions>
      </DialogWrapper>
    </div>
  );
};

export default withStyles(styles)(ErrorMessageDialog);
