import React, { useState } from 'react';

export const DaemonContext = React.createContext({
  daemonStatus: 'offline',
  addDaemonSaveListener: () => {},
  removeDaemonSaveListener: () => {},
  daemonSavedFile: () => {},
  setDaemonStatus: () => {},
});

// TODO: this should most likely not be global.
// These should be scoped to the provider but that is left as an exercise to the reader.

const saveListeners = {};

const addDaemonSaveListener = (name, listener) => {
  saveListeners[name] = listener;
};

const removeDaemonSaveListener = (name) => {
  delete saveListeners[name];
};

const daemonSavedFile = (e) => {
  Object.values(saveListeners).forEach((x) => typeof x === 'function' && x(e));
};

export const DaemonProvider = ({ children }) => {
  const [daemonStatus, setDaemonStatus] = useState('offline');

  return (
    <DaemonContext.Provider
      value={{
        daemonStatus,
        addDaemonSaveListener,
        removeDaemonSaveListener,
        daemonSavedFile,
        setDaemonStatus,
      }}
    >
      {children}
    </DaemonContext.Provider>
  );
};

export const DaemonConsumer = DaemonContext.Consumer;
