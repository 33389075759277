import { setLocale } from 'yup';

/* eslint-disable no-template-curly-in-string */
// Disable this warning since yup template strings uses the same syntax as TS templates.

export const setYupLocale = () => {
  setLocale({
    mixed: {
      default: 'Felaktigt värde.',
      notType: 'Felaktigt värde.',
      required: 'Värdet saknas.',
    },
    number: {
      integer: 'Värdet måste vara ett heltal.',
      min: 'Värdet får inte vara mindre än ${min}.',
      max: 'Värdet får inte vara högre än ${max}.',
    },
    string: {
      max: 'Värdet får inte vara längre än ${max}.',
    },
  });
};
