import React from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <Grid container justifyContent='center'>
        <Card>
          <CardContent>
            <Typography variant='h5'>Något gick fel!</Typography>
          </CardContent>
          <CardActions>
            <Button
              size='small'
              color='primary'
              onClick={() => window.location.reload()}
            >
              Gå till förstasidan
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

export default ErrorBoundary;
