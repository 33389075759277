import { useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import DialogWrapper from '../../../components/dialog-wrapper';

export const styles = (theme) => ({
  confirmContent: {
    padding: 20,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  confirmTitle: {
    backgroundColor: '#ff9800',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
  },
});

function DeleteDocumentsConfirm({ isDeleting, onConfirm, onCancel, classes }) {
  const [loading, setLoading] = useState(false);
  return (
    <DialogWrapper open={isDeleting} onClose={onCancel}>
      <DialogTitle disableTypography className={classes.confirmTitle}>
        <Typography
          className={classes.dialogTitleText}
          color='textSecondary'
          variant='h5'
        >
          <Warning />
          Varning
        </Typography>
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        <DialogContentText className={classes.confirmContent}>
          Är du säker att ta bort filerna?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onCancel}>
          Nej
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setLoading(true);
            try {
              if (onConfirm) onConfirm();
            } finally {
              setLoading(false);
            }
          }}
        >
          Ja
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}

export default withStyles(styles)(DeleteDocumentsConfirm);
