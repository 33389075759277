import React, { useCallback } from 'react';

import {
  AppBar,
  Dialog,
  IconButton,
  LinearProgress,
  Slide,
  Toolbar,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: '800px',
    width: '100%',
  },
  appbar: {
    position: 'relative',
    color: '#000',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
  },
  toolbar: {
    minHeight: 0,
    height: 0,
    padding: 0,
  },
  spacer: {
    flex: '1 auto',
  },
  closeIcon: {
    marginTop: 53,
    marginRight: 5,
  },
}));

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DialogWrapper = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { paperClass } = props;

  const handleClose = useCallback(
    (_e, reason) => {
      if (reason === 'backdropClick') {
        return;
      }
      props.onClose();
    },
    [props]
  );

  return (
    <Dialog
      style={{ paddingRight: 0 }}
      open={props.open || false}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen={props.fullScreen || fullScreen}
      aria-labelledby={props.ariaLabelledby || ''}
      PaperProps={props.PaperProps}
      classes={{ paper: paperClass || classes.dialogPaper }}
    >
      {props.loading && <LinearProgress />}
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.spacer} />
          <IconButton
            color='inherit'
            onClick={() => props.onClose()}
            aria-label='Close'
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {props.children}
    </Dialog>
  );
};

export default DialogWrapper;
