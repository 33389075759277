import { useRef, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';

import ProfileDialog from '../profile-dialog';
import UserAvatar from '../user-avatar';

export function ProfileMenu({ user, handleLogout }) {
  const [open, setOpen] = useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const anchorEl = useRef(null);

  return (
    <>
      <IconButton
        aria-owns={open ? 'menu-appbar' : null}
        aria-haspopup='true'
        onClick={() => setOpen((o) => !o)}
        color='inherit'
        ref={anchorEl}
      >
        <UserAvatar user={user} />
      </IconButton>
      {anchorEl.current && (
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <MenuItem
            onClick={() => {
              setOpen(false);
              setProfileDialogOpen(true);
            }}
          >
            Min profil
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpen(false);
              handleLogout();
            }}
          >
            Logga ut
          </MenuItem>
        </Menu>
      )}
      <ProfileDialog
        open={profileDialogOpen}
        onClose={() => setProfileDialogOpen(false)}
      />
    </>
  );
}

export default ProfileMenu;
