import styled from '@emotion/styled';
import { LeafletMouseEvent } from 'leaflet';
import L from 'leaflet';
import { VFC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  LayerGroup,
  Marker,
  Tooltip as MarkerTooltip,
  Polyline,
  useMap,
} from 'react-leaflet';

import { Tooltip } from '@material-ui/core';
import { Straighten } from '@material-ui/icons';

import { ControlIconButton } from '../../../components/map/control-components';
import {
  CustomControlProps,
  CustomMapControl,
} from '../../../components/map/map-custom-control';
import blueDotImage from './blue-dot.svg';

const WhiteBackgroundDiv = styled.div({
  backgroundColor: 'white',
});

const formatDistance = (d: number) => {
  let unit = 'm';
  if (d > 1000) {
    d = d / 1000;
    unit = 'km';
  }

  if (d < 100) {
    return d.toFixed(1) + ' ' + unit;
  } else {
    return Math.round(d) + ' ' + unit;
  }
};

const circleIcon = new L.Icon({
  iconUrl: blueDotImage,
  iconSize: new L.Point(16, 16),
  className: '',
});

export interface MeasureControlProps extends CustomControlProps {}

export const MeasureControl: VFC<MeasureControlProps> = ({
  ...controlProps
}) => {
  const map = useMap();

  const [measureToolState, setMeasureToolState] = useState({
    enabled: false,
    dragging: false,
  });
  const [measurementLayer, setMeasurementLayer] = useState<L.LatLng[]>([]);

  const rulerRef = useRef<L.LayerGroup>(null);
  const distanceLabelLayerRef = useRef<L.LayerGroup>(null);
  const lineRef = useRef<L.Polyline>(null);

  const handleMapClick = useCallback(
    (e: LeafletMouseEvent) => {
      if (map != null) {
        if (measureToolState.enabled && !measureToolState.dragging) {
          const layer = [...measurementLayer, e.latlng];
          setMeasurementLayer(layer);
        }
      }
    },
    [map, measureToolState.enabled, measureToolState.dragging, measurementLayer]
  );

  const clearRulerPoints = useCallback(() => {
    const rulerLayer = rulerRef.current;
    const labelLayer = distanceLabelLayerRef.current;
    labelLayer?.clearLayers();

    setMeasurementLayer([]);
    if (rulerLayer != null) rulerLayer.clearLayers();
  }, [setMeasurementLayer]);

  const circleMarkerEventHandler = useMemo<L.LeafletEventHandlerFnMap>(
    () => ({
      mousedown: (e) => {
        if (map != null) {
          setMeasureToolState({ ...measureToolState, dragging: true });

          const currentMarkerPoint = e.latlng;
          const index = measurementLayer.findIndex(
            (m) =>
              m.lat === currentMarkerPoint.lat &&
              m.lng === currentMarkerPoint.lng
          );
          map.removeEventListener('click');
          map.dragging.disable();
          map.addEventListener('mousemove', (e) => {
            if (index >= 0) {
              measurementLayer[index] = e.latlng;
              setMeasurementLayer([...measurementLayer]);
            }
          });
          map.once('mouseup', () => {
            L.DomUtil.enableTextSelection();
            map.dragging.enable();
            map.removeEventListener('mousemove');
            setTimeout(() => {
              map.addEventListener('click', handleMapClick);
              setMeasureToolState({ ...measureToolState, dragging: false });
            });
          });
        }
      },
    }),
    [handleMapClick, map, measureToolState, measurementLayer]
  );

  const lastMarkerTooltip = useCallback(
    (m: L.LatLng) => {
      if (m === null) return null;
      if (measurementLayer.length <= 2) return null;

      const lastMarker = measurementLayer.at(-1);
      let total = 0;
      if (
        lastMarker != null &&
        lastMarker.lat === m.lat &&
        lastMarker.lng === m.lng
      ) {
        for (let index = 1; index < measurementLayer.length; index++) {
          const p1 = measurementLayer[index - 1] as L.LatLng;
          const p2 = measurementLayer[index] as L.LatLng;
          const distance = p1.distanceTo(p2);
          total = total + distance;
        }
      }

      return total === 0 ? null : formatDistance(total);
    },
    [measurementLayer]
  );

  useEffect(() => {
    if (measurementLayer?.length < 2) return;

    const labelLayer = distanceLabelLayerRef.current;
    labelLayer?.clearLayers();

    for (let index = 1; index < measurementLayer.length; index++) {
      const p1 = measurementLayer[index - 1];
      const p2 = measurementLayer[index];

      const distance = p1.distanceTo(p2);
      const labelCenter = new L.LatLng(
        (p1.lat + p2.lat) / 2,
        (p1.lng + p2.lng) / 2
      );

      const labelMarker = L.circleMarker(labelCenter, {
        radius: 2,
      }).bindTooltip(formatDistance(distance), {
        permanent: true,
        direction: 'center',
      });

      if (labelLayer != null) {
        labelMarker.addTo(labelLayer);
      }
    }
  }, [measurementLayer, measureToolState.enabled]);

  useEffect(() => {
    if (measureToolState.enabled) {
      map.on('click', handleMapClick);
    } else {
      map.off('click');
    }
  }, [handleMapClick, map, measureToolState.enabled]);

  return (
    <>
      <LayerGroup ref={rulerRef}>
        {measurementLayer?.map((p: L.LatLng, index) => {
          const totalDistance = lastMarkerTooltip(p);
          return (
            <Marker
              position={p}
              draggable={true}
              eventHandlers={circleMarkerEventHandler}
              icon={circleIcon}
              key={index}
            >
              {totalDistance != null && (
                <MarkerTooltip permanent={true}>{totalDistance}</MarkerTooltip>
              )}
            </Marker>
          );
        })}
        {measureToolState.enabled && (
          <Polyline weight={6} positions={measurementLayer} ref={lineRef} />
        )}
      </LayerGroup>
      <LayerGroup ref={distanceLabelLayerRef}> </LayerGroup>

      <CustomMapControl {...controlProps}>
        <WhiteBackgroundDiv>
          <Tooltip title='Mät i kartan'>
            <ControlIconButton
              onClick={() => {
                const enabled = !measureToolState.enabled;
                const update = { ...measureToolState, enabled: enabled };
                setMeasureToolState(update);

                if (!enabled) {
                  clearRulerPoints();
                }
              }}
            >
              <Straighten
                style={{ color: measureToolState.enabled ? 'green' : 'black' }}
              />
            </ControlIconButton>
          </Tooltip>
        </WhiteBackgroundDiv>
      </CustomMapControl>
    </>
  );
};
