import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const renderInput = (inputProps) => {
  const { InputProps, classes, ref, disabled, ...other } = inputProps;
  return (
    <TextField
      margin='dense'
      disabled={disabled || false}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
};

const renderSuggestion = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component='div'
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.name}
    </MenuItem>
  );
};
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const getSuggestions = (value, items) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : items.filter((suggestion) => {
        const keep =
          count < 10 &&
          ((suggestion.name &&
            suggestion.name.toLowerCase().includes(inputValue)) ||
            (suggestion.optionalSearchParameter &&
              suggestion.optionalSearchParameter
                .toLowerCase()
                .includes(inputValue)) ||
            (suggestion.referenceNumber &&
              suggestion.referenceNumber.toString().includes(inputValue)));

        if (keep) {
          count += 1;
        }

        return keep;
      });
};

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
});

const IntegrationDownshift = (props) => {
  const {
    classes,
    label,
    placeholder,
    required,
    onChange,
    onBlur,
    name,
    items,
    error,
    helperText,
    initialSelectedItem,
    selectedItem,
    anchorEl,
    disabled,
    allowFreeInput,
  } = props;

  const inputRef = useRef();

  const handleBlur = () => {
    onBlur?.({ target: { name } });
  };

  return (
    <Downshift
      initialSelectedItem={initialSelectedItem}
      selectedItem={selectedItem}
      onChange={(selection) => {
        const selectedItem = items.find((i) => i.name === selection);
        onChange({
          target: {
            name,
            value: selectedItem && selectedItem.id,
            secondaryValue: selectedItem && selectedItem.name,
            rawValue: selection,
          },
        });
      }}
      stateReducer={(state, changes) => {
        if (changes.type === Downshift.stateChangeTypes.changeInput) {
          const match = items.findIndex((i) => i.name === changes.inputValue);
          if (match >= 0) {
            changes = {
              ...changes,
              highlightedIndex: match,
              selectedItem: changes.inputValue,
            };
          } else {
            if (allowFreeInput) {
              changes = {
                ...changes,
                selectedItem: changes.inputValue,
              };
            }
          }
        }

        return changes;
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        clearSelection,
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            required,
            disabled,
            InputProps: getInputProps({
              placeholder,
              onChange: (e) => {
                // If input text is cleared, also clear selection
                if (e.target.value === '') {
                  clearSelection();
                }
              },
              onBlur: handleBlur,
            }),
            label,
            error,
            helperText,
            ref: inputRef,
          })}
          <Popper
            open={isOpen}
            anchorEl={anchorEl ?? inputRef.current}
            style={{ zIndex: 1301 }}
          >
            <div
              {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}
            >
              <Paper
                square
                style={{
                  marginTop: 8,
                  width: inputRef.current?.clientWidth,
                }}
              >
                {getSuggestions(inputValue, items).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion.name }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
              </Paper>
            </div>
          </Popper>
        </div>
      )}
    </Downshift>
  );
};

export default withStyles(styles)(IntegrationDownshift);
