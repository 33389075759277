import { lazy } from 'react';

import { AdminPolicy, ContactPersonPolicy } from '../policies';

const Dashboard = lazy(() => import('./dashboard'));
const Documents = lazy(() => import('./documents'));
const MeasuringData = lazy(() => import('./measuring-data'));
const MeasuringPoints = lazy(() => import('./measuring-points'));
const MeasuringCards = lazy(() => import('./measuring-cards'));
const Users = lazy(() => import('./users'));
const Blasts = lazy(() => import('./blasts'));
const Noise = lazy(() => import('./noise'));
const Notifications = lazy(() => import('./notifications'));
const Analytics = lazy(() => import('./analytics'));

const routes = [
  {
    path: '/project/:referenceNumber',
    nameInPath: '',
    component: Dashboard,
    name: 'Översikt',
    icon: 'dashboard',
    exact: true,
    allowedRoles: AdminPolicy,
  },
  {
    path: '/project/:referenceNumber/users',
    nameInPath: 'users',
    component: Users,
    name: 'Projektanvändare',
    icon: 'people',
    allowedRoles: AdminPolicy,
  },
  {
    path: '/project/:referenceNumber/measuringdata',
    nameInPath: 'measuringdata',
    component: MeasuringData,
    name: 'Mätdata',
    icon: 'trending_up',
    allowedRoles: ContactPersonPolicy,
  },
  {
    path: '/project/:referenceNumber/documents',
    nameInPath: 'documents',
    component: Documents,
    name: 'Dokument',
    icon: 'attach_file',
    allowedRoles: AdminPolicy,
  },
  {
    path: '/project/:referenceNumber/measuringpoints',
    nameInPath: 'measuringpoints',
    component: MeasuringPoints,
    name: 'Mätpunkter',
    icon: 'location_on',
    allowedRoles: AdminPolicy,
  },
  {
    path: '/project/:referenceNumber/measuringcard/:measuringCardId',
    nameInPath: 'measuringcard',
    component: MeasuringCards,
    allowedRoles: AdminPolicy,
    hidden: true,
  },
  {
    path: '/project/:referenceNumber/measuringcards',
    nameInPath: 'measuringcards',
    component: MeasuringCards,
    name: 'Mätarkort',
    icon: 'assignment',
    allowedRoles: AdminPolicy,
  },
  {
    path: '/project/:referenceNumber/blasts',
    nameInPath: 'blasts',
    component: Blasts,
    name: 'Salvor',
    icon: 'offline_bolt',
    allowedRoles: ContactPersonPolicy,
    isBlastRoute: true,
  },
  {
    path: '/project/:referenceNumber/noise',
    nameInPath: 'noise',
    component: Noise,
    name: 'Buller/Stomljud',
    icon: 'surround_sound',
    allowedRoles: ContactPersonPolicy,
  },
  {
    path: '/project/:referenceNumber/notifications',
    nameInPath: 'notifications',
    component: Notifications,
    name: 'Notifikationer',
    icon: 'sms',
    allowedRoles: AdminPolicy,
  },
  !!process.env.REACT_APP_ENABLE_TELLUS_ANALYTICS && {
    path: '/project/:referenceNumber/analytics',
    nameInPath: 'analytics',
    component: Analytics,
    name: 'DTWeb',
    icon: 'analytics',
    allowedRoles: AdminPolicy,
  },
].filter((x) => !!x);

export default routes;
