import { Icon, Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  info: {
    background: 'rgba(83,182,255,0.15)',
    border: '1px solid rgba(0,90,156,0.4)',
    '& .title': {
      color: 'rgba(0,90,156,1)',
      fontSize: 'large',
      fontWeight: 'bold',
      display: 'block',
    },
    '& .text': {
      color: 'rgba(1,101,175,1)',
    },
    '& .icon': {
      color: 'rgba(0,90,156,1)',
      backgroundColor: 'transparent',
    },
  },
  success: {
    background: 'rgba(0,185,0,0.06)',
    border: '1px solid rgba(0,128,0,0.4)',
    '& .title': {
      color: 'rgba(32,123,32,1)',
      fontSize: 'large',
      fontWeight: 'bold',
      display: 'block',
    },
    '& .text': {
      color: 'green',
    },
    '& .icon': {
      color: 'rgba(32,123,32,1)',
      backgroundColor: 'transparent',
    },
  },
  error: {
    background: 'rgba(226,19,4,0.08)',
    border: '1px solid rgba(226, 19, 4, 0.4)',
    '& .title': {
      color: 'rgba(185,3,3,1)',
      fontSize: 'large',
      fontWeight: 'bold',
      display: 'block',
    },
    '& .text': {
      color: 'rgba(206,0,0,1)',
    },
    '& .icon': {
      color: 'rgba(185,3,3,1)',
      backgroundColor: 'transparent',
    },
  },
  field: {
    width: 'calc(100% - 32px)',
    margin: 'auto',
    marginBottom: '16px',
    borderRadius: '5px',
    padding: '16px 24px',
  },
});

function HighlightedFieldset({ title, text, icon, mode, classes }) {
  if (mode !== 'info' && mode !== 'success' && mode !== 'error') {
    throw new Error("Mode has to be one of ['info', 'mode', 'error']");
  }

  let modeClass =
    mode === 'info'
      ? classes.info
      : mode === 'success'
      ? classes.success
      : classes.error;
  return (
    <fieldset className={`${classes.field} ${modeClass}`}>
      {icon && (
        <legend>
          <Icon className={'icon'} fontSize='large'>
            {icon}
          </Icon>
        </legend>
      )}
      {title && (
        <Typography variant='subtitle2' className={'title'}>
          {title}
        </Typography>
      )}
      {text && (
        <Typography variant='subtitle2' className={'text'}>
          {text}
        </Typography>
      )}
    </fieldset>
  );
}

export default withStyles(styles)(HighlightedFieldset);
