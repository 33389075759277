import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useDebounce } from '@react-hook/debounce';
import L from 'leaflet';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMap } from 'react-leaflet';

import { Divider, Input, Tooltip, styled } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';

import { OsmSearchResult, osmSearch } from '../../common/osm-search';
import { DefaultMapBoundsPadding, DefaultMapMaxZoom } from './base-map';
import { ControlBackground, ControlIconButton } from './control-components';
import { CustomControlProps, CustomMapControl } from './map-custom-control';

const BackgroundDiv = styled(ControlBackground)({
  display: 'flex',
  flexDirection: 'column',
});

const SearchDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

interface ResultsDivProps {
  open: boolean;
  width: number;
  height: number;
}
const ResultsDiv = styled('div')((props: ResultsDivProps) => ({
  padding: 0,
  maxWidth: props.width,
  maxHeight: props.height - 30,
  overflowX: 'clip',
  overflowY: 'auto',
  display: !props.open ? 'none' : undefined,
}));

interface ResultDivProps {
  selected?: boolean;
  notFound?: boolean;
}
const ResultDiv = styled('div')((props: ResultDivProps) => ({
  padding: '4px 8px 4px 8px',
  textAlign: 'center',
  cursor: !props.notFound ? 'pointer' : undefined,
  backgroundColor: props.selected ? '#70bbe0' : undefined,
  '&:hover': {
    backgroundColor: !props.notFound ? '#CCC' : undefined,
  },
}));

const SearchInput = styled(Input)({
  height: 30,
  flex: '1 auto',
});

interface InputDivProps {
  width: number;
  open: boolean;
}

const InputDiv = styled('div')((props: InputDivProps) => ({
  width: props.width - 30,
  height: 30,
  display: props.open ? 'flex' : 'none',
  flexDirection: 'row',
}));

export interface SearchControlProps extends CustomControlProps {
  width?: number;
  height?: number;
}

export const SearchControl: React.VFC<SearchControlProps> = ({
  width = 250,
  height = 180,
  ...controlProps
}) => {
  const map = useMap();
  const appInsights = useAppInsightsContext();

  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [searchResult, setSearchResult] = useState<OsmSearchResult[]>([]);
  const [searchError, setSearchError] = useState<string | undefined>();
  const [showResult, setShowResult] = useState(false);
  const [selectedResult, setSelectedResult] = useState<number | undefined>();

  const [debouncedQuery, setDebouncedQuery, setDebouncedQueryNow] = useDebounce(
    query,
    500
  );

  useEffect(() => setDebouncedQuery(query), [query, setDebouncedQuery]);

  const openSearch = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeSearch = useCallback(() => {
    setQuery('');
    setDebouncedQueryNow('');
    setIsOpen(false);
    setSearchError(undefined);
  }, [setDebouncedQueryNow]);

  const toggleOpen = useCallback(() => {
    if (isOpen) {
      closeSearch();
    } else {
      openSearch();
    }
  }, [closeSearch, isOpen, openSearch]);

  const handleResultClick = useCallback(
    (result: OsmSearchResult) => {
      setShowResult(false);
      setSelectedResult(result.place_id);
      const position = new L.LatLng(result.lat, result.lon);

      map.fitBounds(
        [
          [result.boundingbox[0], result.boundingbox[2]],
          [result.boundingbox[1], result.boundingbox[3]],
        ],
        { padding: DefaultMapBoundsPadding, maxZoom: DefaultMapMaxZoom }
      );

      map.openPopup(result.display_name, position);
    },
    [map]
  );

  useEffect(() => {
    setSearchResult([]);
    setSelectedResult(undefined);
    setSearchError(undefined);

    if (debouncedQuery.length < 3) {
      return;
    }

    let cancel = false;
    (async () => {
      const queryCopy = debouncedQuery;
      try {
        const results = await osmSearch(queryCopy);
        if (cancel) {
          return;
        }

        if (results.length === 0) {
          setSearchError('Hittades ej');
        } else {
          setSearchResult(results);
        }
      } catch (error: any) {
        appInsights.trackException(
          { exception: error, severityLevel: SeverityLevel.Warning },
          { query: queryCopy }
        );
        console.warn('OSM search failed', error);
        setSearchError('Sökningen misslyckades');
      }
    })();

    return () => {
      cancel = false;
    };
  }, [appInsights, debouncedQuery]);

  const results = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    if (searchError != null) {
      return (
        <ResultsDiv open={showResult} width={width} height={height}>
          <Divider />
          <ResultDiv notFound>{searchError}</ResultDiv>
        </ResultsDiv>
      );
    }

    return (
      <ResultsDiv open={showResult} width={width} height={height}>
        {searchError != null ? (
          <>
            <Divider />
            <ResultDiv notFound>{searchError}</ResultDiv>
          </>
        ) : (
          searchResult.map((result) => (
            <Fragment key={result.place_id}>
              <Divider />
              <ResultDiv
                selected={result.place_id === selectedResult}
                onClick={() => handleResultClick(result)}
              >
                {result.display_name}
              </ResultDiv>
            </Fragment>
          ))
        )}
      </ResultsDiv>
    );
  }, [
    handleResultClick,
    height,
    isOpen,
    searchError,
    searchResult,
    selectedResult,
    showResult,
    width,
  ]);

  return (
    <CustomMapControl disableScrollPropagation {...controlProps}>
      <BackgroundDiv>
        <SearchDiv>
          <Tooltip title='Sök adress'>
            <ControlIconButton onClick={toggleOpen}>
              <Search />
            </ControlIconButton>
          </Tooltip>
          <InputDiv width={width} open={isOpen}>
            {isOpen && (
              <SearchInput
                autoFocus
                placeholder='Sök adress'
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onFocus={() => setShowResult(true)}
                disableUnderline
              />
            )}
            <ControlIconButton onClick={closeSearch}>
              <Close />
            </ControlIconButton>
          </InputDiv>
        </SearchDiv>
        {results}
      </BackgroundDiv>
    </CustomMapControl>
  );
};
