import { useQuery } from '@apollo/client';
import { useAsyncEffect } from '@react-hook/async';
import { parseISO } from 'date-fns';
import L from 'leaflet';
import 'leaflet.markercluster';
import { VFC, useCallback, useEffect, useMemo, useState } from 'react';
import { ZoomControl, useMap } from 'react-leaflet';
import 'react-leaflet-markercluster/dist/styles.min.css';

import { notEmpty } from '../../../common/util';
import {
  DefaultMapBoundsPadding,
  DefaultMapMaxZoom,
} from '../../../components/map/base-map';
import { FullMapWidget } from '../../../components/map/full-map-widget';
import { MarkerColor } from '../../../components/map/icon-marker';
import { SearchControl } from '../../../components/map/search-control';
import { ProjectService } from '../../../generated/tellus';
import { BlastMarker, createBlastMarker } from './blast-marker';
import { LeftPaneControl } from './left-pane-control';
import { MarkerLegend } from './marker-legend';
import { MeasureControl } from './measure-control';
import { createMeasurePointMarker } from './measuring-point-marker';
import {
  getProjectLocation,
  isMeasuringPointActive,
  mapDataQueryDocument,
  useMapFilter,
} from './model';
import { ProjectLayers } from './project-layers';

const getMPType = (channels: { type?: string | null }[]) => {
  return channels.map((x) => x.type).filter(notEmpty)[0];
};

interface ProjectMapImplProps {
  projectNumber: string;
  defaultSelectedBlastId?: number;
  defaultClusterMarkers?: boolean;
  defaultCoordinates?: L.LatLngExpression;
}

const ProjectMapImpl: VFC<ProjectMapImplProps> = ({
  projectNumber,
  defaultSelectedBlastId,
  defaultClusterMarkers = true,
  defaultCoordinates,
}) => {
  const map = useMap();

  const [zoom, setZoom] = useState(() => map.getZoom());
  const [selectedBlast, setSelectedBlast] = useState<BlastMarker>();
  const [clusterMarkers, setClusterMarkers] = useState(defaultClusterMarkers);

  const disablePointClusteringAtZoom = useMemo(
    () => map.getMaxZoom() - 1,
    [map]
  );

  const showAllGroup = useMemo(
    () =>
      new L.MarkerClusterGroup({
        chunkedLoading: true,
        showCoverageOnHover: false,
        disableClusteringAtZoom: 0,
      }),
    []
  );

  const clusterGroup = useMemo(
    () =>
      new L.MarkerClusterGroup({
        chunkedLoading: true,
        showCoverageOnHover: false,
        maxClusterRadius: 100,
        spiderfyDistanceMultiplier: 1.5,
        disableClusteringAtZoom: disablePointClusteringAtZoom,
      }),
    [disablePointClusteringAtZoom]
  );

  const blastClusterGroup = useMemo(
    () =>
      new L.MarkerClusterGroup({
        chunkedLoading: true,
        showCoverageOnHover: false,
        maxClusterRadius: 100,
        spiderfyDistanceMultiplier: 1.5,
      }),
    []
  );

  useEffect(() => {
    map.addLayer(showAllGroup);

    return () => {
      map.removeLayer(showAllGroup);
    };
  }, [showAllGroup, map]);

  useEffect(() => {
    map.addLayer(clusterGroup);

    return () => {
      map.removeLayer(clusterGroup);
    };
  }, [clusterGroup, map]);

  useEffect(() => {
    map.addLayer(blastClusterGroup);

    return () => {
      map.removeLayer(blastClusterGroup);
    };
  }, [blastClusterGroup, map]);

  useEffect(() => {
    const handler = (ev: L.LeafletEvent) => {
      setZoom(ev.target.getZoom());
    };
    map.on('zoom', handler);

    return () => {
      map.off('zoom', handler);
    };
  }, [map]);

  const [mapFilter, setMapFilter] = useMapFilter();

  // load project location
  const { data: projectlocationData } = useQuery(getProjectLocation, {
    variables: { referenceNumber: projectNumber },
  });

  // Load measure points and cards
  const { data, loading: measurePointsLoading } = useQuery(
    mapDataQueryDocument,
    {
      variables: { referenceNumber: projectNumber },
      fetchPolicy: 'network-only',
    }
  );

  // Load blasts
  const { value: blasts = [], status: blastsStatus } = useAsyncEffect(
    () => ProjectService.blastWithColor({ referenceNumber: projectNumber }),
    [projectNumber]
  );

  const blastsCount = useMemo(() => {
    return blasts?.length ?? 0;
  }, [blasts?.length]);

  const blastsLoading = useMemo(
    () => blastsStatus === 'loading',
    [blastsStatus]
  );

  const measuringCards = useMemo(
    () =>
      data?.project?.measuringCards
        ?.filter(notEmpty)
        .filter((x) => x.isActive) ?? [],
    [data?.project?.measuringCards]
  );

  const allChannels = useMemo(
    () =>
      measuringCards
        .flatMap((x) => x.channels ?? [])
        .filter(notEmpty)
        .filter((x) => x.measuringPointId != null && x.isActive),
    [measuringCards]
  );

  const measuringPoints = useMemo(
    () =>
      data?.project?.measuringPoints
        ?.filter(notEmpty)
        .filter(
          (x) =>
            x.latitude != null &&
            x.longitude != null &&
            allChannels.some((c) => c.measuringPointId === x.id)
        ) ?? [],
    [allChannels, data?.project?.measuringPoints]
  );

  const allMeasuringPoints = useMemo(
    () =>
      measuringPoints
        .map((measuringPoint) => {
          const measuringPointChannels = allChannels.filter(
            (channel) => channel.measuringPointId === measuringPoint.id
          );

          const measuringPointCards = measuringCards.filter((card) =>
            measuringPointChannels.some((c) => c.measuringCardId === card.id)
          );

          return {
            ...measuringPoint,
            type: getMPType(measuringPointChannels),
            projectNumber: projectNumber,
            channels: measuringPointChannels,
            cards: measuringPointCards,
          };
        })
        .sort((a, b) => {
          const isActiveA = isMeasuringPointActive(a);
          const isActiveB = isMeasuringPointActive(b);
          if (isActiveA !== isActiveB) {
            return isActiveA ? -1 : 1;
          }

          return a.number - b.number;
        }),
    [measuringPoints, allChannels, measuringCards, projectNumber]
  );

  const projectLocation = useMemo(() => {
    if (
      projectlocationData?.project?.latitude != null &&
      projectlocationData?.project?.longitude != null
    ) {
      return L.latLng(
        projectlocationData.project.latitude,
        projectlocationData.project.longitude
      );
    }
  }, [
    projectlocationData?.project?.latitude,
    projectlocationData?.project?.longitude,
  ]);

  const objectBounds = useMemo(() => {
    const mpLocations = measuringPoints
      .filter((x) => x.latitude != null && x.longitude != null)
      .map((measuringPoint) =>
        L.latLng(measuringPoint.latitude!, measuringPoint.longitude!)
      );

    const blastLocations = blasts.map((blast) =>
      L.latLng(blast.latitude, blast.longitude)
    );

    const locations = [...mpLocations, ...blastLocations];

    return L.latLngBounds(locations);
  }, [blasts, measuringPoints]);

  useEffect(() => {
    if (defaultCoordinates == null) {
      return;
    }

    map.setView(defaultCoordinates, DefaultMapMaxZoom, { animate: false });
  }, [defaultCoordinates, map]);

  useEffect(() => {
    if (defaultCoordinates != null) {
      return;
    }

    if (objectBounds.isValid()) {
      map.fitBounds(objectBounds, { padding: DefaultMapBoundsPadding });
    } else if (projectLocation != null) {
      map.setView(projectLocation, 10, { animate: false });
    }
  }, [defaultCoordinates, map, objectBounds, projectLocation]);

  // Create measuring point markers
  const measuringPointMarkers = useMemo(
    () => allMeasuringPoints.map((m) => createMeasurePointMarker(m, map)),
    [allMeasuringPoints, map]
  );

  // Create blast markers
  const blastMarkers = useMemo(
    () => blasts.map((b) => createBlastMarker(projectNumber, b, map)),
    [blasts, map, projectNumber]
  );

  // Select default blast
  useEffect(() => {
    const marker = blastMarkers.find(
      (x) => x.blast.id === defaultSelectedBlastId
    );

    setSelectedBlast(marker);
  }, [blastMarkers, defaultSelectedBlastId]);

  // Apply blast marker filter
  const visibleBlastMarkers = useMemo(() => {
    if (!mapFilter.showBlasts) {
      return [];
    }

    return blastMarkers;
  }, [blastMarkers, mapFilter.showBlasts]);

  // Apply measuring point filter
  const visiblePointMarkers = useMemo(() => {
    const activePrefixes = mapFilter.types
      .filter((x) => x.show)
      .map((x) => x.prefix);

    return measuringPointMarkers
      .filter(({ measuringPoint }) => {
        const isActive = isMeasuringPointActive(measuringPoint);
        return (
          (mapFilter.showActive && isActive) ||
          (mapFilter.showInactive && !isActive)
        );
      })
      .filter(({ measuringPoint }) => {
        return measuringPoint.channels.some((channel) =>
          activePrefixes.some(
            (prefix) => channel.type?.startsWith(prefix) ?? false
          )
        );
      })
      .filter(({ measuringPoint }) =>
        measuringPoint.cards.some(
          (card) =>
            (card.validFrom == null ||
              parseISO(card.validFrom) <= mapFilter.to) &&
            (card.validTo == null || parseISO(card.validTo) >= mapFilter.from)
        )
      );
  }, [
    mapFilter.from,
    mapFilter.showActive,
    mapFilter.showInactive,
    mapFilter.to,
    mapFilter.types,
    measuringPointMarkers,
  ]);

  const clusterMeasuringPoints = useMemo(
    () => zoom < disablePointClusteringAtZoom,
    [disablePointClusteringAtZoom, zoom]
  );

  const measurePointsGroup = useMemo(
    () => (clusterMarkers ? clusterGroup : showAllGroup),
    [clusterGroup, clusterMarkers, showAllGroup]
  );

  const blastsGroup = useMemo(
    () =>
      clusterMarkers
        ? clusterMeasuringPoints
          ? clusterGroup
          : blastClusterGroup
        : showAllGroup,
    [
      blastClusterGroup,
      clusterGroup,
      clusterMeasuringPoints,
      clusterMarkers,
      showAllGroup,
    ]
  );

  // Add point markers to map
  useEffect(() => {
    measurePointsGroup.addLayers(
      visiblePointMarkers.map(({ marker }) => marker)
    );

    return () => {
      for (let { marker } of visiblePointMarkers) {
        measurePointsGroup.removeLayer(marker);
      }
    };
  }, [measurePointsGroup, visiblePointMarkers]);

  // Add blast markers to map
  useEffect(() => {
    blastsGroup.addLayers(visibleBlastMarkers.map(({ marker }) => marker));

    return () => {
      for (let { marker } of visibleBlastMarkers) {
        blastsGroup.removeLayer(marker);
      }
    };
  }, [blastsGroup, visibleBlastMarkers]);

  const showMeasuringPoint = useCallback(
    (id: number) => {
      const marker = measuringPointMarkers.find(
        ({ measuringPoint }) => measuringPoint.id === id
      );

      if (marker == null) {
        return;
      }

      measurePointsGroup.zoomToShowLayer(marker.marker, () => {
        marker.marker.openPopup();
      });
    },
    [measurePointsGroup, measuringPointMarkers]
  );

  useEffect(() => {
    const markerHandlers = blastMarkers.map((x) => ({
      marker: x.marker,
      handler: () => setSelectedBlast(x),
    }));

    markerHandlers.forEach(({ marker, handler }) => {
      marker.on('click', handler);
    });

    return () => {
      markerHandlers.forEach(({ marker, handler }) => {
        marker.off('click', handler);
      });
    };
  }, [blastMarkers]);

  useEffect(() => {
    const handler = () => {
      setSelectedBlast(undefined);
    };

    map.on('click', handler);
    return () => {
      map.off('click', handler);
    };
  }, [map]);

  useEffect(() => {
    if (selectedBlast == null) {
      return;
    }

    visibleBlastMarkers.forEach((x) => {
      if (x.blast.id === selectedBlast.blast.id) {
        x.highlight();
        x.marker.openPopup();
      } else {
        x.fade();
      }
    });

    visiblePointMarkers.forEach((x) => {
      // We check for undefined since it may contain null values.
      if (
        selectedBlast.blast.measuringPointColors?.[x.measuringPoint.id] !==
        undefined
      ) {
        x.highlight();
      } else {
        x.fade();
      }
    });

    return () => {
      visibleBlastMarkers.forEach((x) => {
        x.reset();
      });
      visiblePointMarkers.forEach((x) => {
        x.reset();
      });
    };
  }, [blastsGroup, selectedBlast, visibleBlastMarkers, visiblePointMarkers]);

  return (
    <>
      <LeftPaneControl
        measuringPoints={allMeasuringPoints}
        measurePointsLoading={measurePointsLoading}
        clusterMarkers={clusterMarkers}
        setClusterMarkers={setClusterMarkers}
        mapFilter={mapFilter}
        setMapFilter={setMapFilter}
        showMeasuringPoint={showMeasuringPoint}
        blastsCount={blastsCount}
        blastsLoading={blastsLoading}
      />

      <ZoomControl position='topright' />
      <SearchControl
        disableClickPropagation={true}
        height={360}
        position='topright'
      />
      <MeasureControl position='topright' />

      <MarkerLegend position='bottomright' />

      <ProjectLayers projectNumber={projectNumber} />

      {visiblePointMarkers.map(({ Component, measuringPoint }) => (
        <Component
          key={measuringPoint.id}
          color={
            selectedBlast?.blast.measuringPointColors?.[
              measuringPoint.id
            ] as MarkerColor
          }
        />
      ))}
      {visibleBlastMarkers.map(({ component }) => component)}
    </>
  );
};

interface ProjectMapProps extends ProjectMapImplProps {
  open: boolean;
  onClose: () => void;
}

export const ProjectMap: VFC<ProjectMapProps> = ({
  open,
  onClose,
  ...props
}) => (
  <FullMapWidget open={open} onClose={onClose} showZoomControl={false}>
    <ProjectMapImpl {...props} />
  </FullMapWidget>
);
