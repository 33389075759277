import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@material-ui/core';

import DialogWrapper from '../../../components/dialog-wrapper';
import DocumentListItem from '../../../components/document-list-item';

class DocumentBin extends React.Component {
  state = {
    loadingIds: [],
    restoredIds: [],
  };

  restoreDocument = async (id) => {
    await this.updateDocumentStatus(id, true);
    this.setState({ restoredIds: [...this.state.restoredIds, id] });
  };

  updateDocumentStatus = async (id, isActive) => {
    this.setState({ loadingIds: [...this.state.loadingIds, id] });
    await this.props.updateDocumentStatus(id, isActive);
    this.setState({
      loadingIds: [...this.state.loadingIds.filter((i) => i !== id)],
    });
  };

  render() {
    const { open, handleClose, deletedDocuments } = this.props;
    return (
      <DialogWrapper
        open={open}
        onClose={handleClose}
        ariaLabelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Borttagna dokument</DialogTitle>
        <DialogContent>
          {deletedDocuments.length > 0 ? (
            <List>
              {deletedDocuments
                .filter((f) => !this.state.restoredIds.includes(f.node.id))
                .map((d) => (
                  <DocumentListItem
                    key={d.node.id}
                    doc={d.node}
                    showPath
                    restoreDocumentMenuVisible
                    restoreDocument={this.restoreDocument}
                    loading={this.state.loadingIds.find((i) => i === d.node.id)}
                  />
                ))}
            </List>
          ) : (
            'Inga borttagna dokument för detta projekt'
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Stäng</Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

export default DocumentBin;
