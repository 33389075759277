const OSMSearchUrl = 'https://nominatim.openstreetmap.org/search';

export interface OsmSearchResult {
  display_name: string;
  lat: number;
  lon: number;
  // min lat, max lat, min lng, max lng
  boundingbox: [number, number, number, number];
  category: string;
  type: string;
  place_rank: number;
  place_id: number;
}

export const osmSearch = async (query: string) => {
  if (query.length < 3) {
    return [];
  }

  const searchParams = new URLSearchParams({
    q: query,
    countrycodes: 'se',
    format: 'jsonv2',
  });

  const url = new URL(OSMSearchUrl);
  url.search = searchParams.toString();

  const response = await fetch(url.toString());

  if (!response.ok) {
    throw new Error(`OSM search failed: ${await response.text()}`);
  }

  const json = await response.json();

  return json as OsmSearchResult[];
};
