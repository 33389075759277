import { ResultOf } from '@graphql-typed-document-node/core';
import { isWithinInterval, parseISO } from 'date-fns';
import { useState } from 'react';

import { EpochDate } from '../../../common/util';
import { graphql } from '../../../generated/gql';

export const getProjectLocation = graphql(`
  query GetProjectLocation($referenceNumber: String!) {
    project(referenceNumber: $referenceNumber) {
      id
      latitude
      longitude
    }
  }
`);

export const mapDataQueryDocument = graphql(`
  query GetMapData($referenceNumber: String!) {
    project(referenceNumber: $referenceNumber) {
      id
      measuringPoints {
        id
        number
        longitude
        latitude
        address
        crs
        z
        v10
        v1
        v2
        noise
        underground
        typeOfWork
        fixedBenchmark
        airblast
        isAddedOnMap
      }
      measuringCards(includeDormant: true) {
        id
        validFrom
        validTo
        isActive
        channels(measuringCardChannelType: "ALL") {
          id
          channel
          type
          sensor
          hiredFrom
          hiredTo
          measuringCardId
          measuringPointId
          isActive
        }
      }
    }
  }
`);

type GetMapDataResult = ResultOf<typeof mapDataQueryDocument>;

type MeasuringPoint = NonNullable<
  NonNullable<
    NonNullable<GetMapDataResult['project']>['measuringPoints']
  >[number]
>;

type MeasuringCard = NonNullable<
  NonNullable<
    NonNullable<GetMapDataResult['project']>['measuringCards']
  >[number]
>;

type MeasuringCardChannel = NonNullable<
  NonNullable<MeasuringCard['channels']>[number]
>;

export interface MeasuringPointExt extends MeasuringPoint {
  type?: string;
  projectNumber?: string;
  cards: MeasuringCard[];
  channels: MeasuringCardChannel[];
}

export interface Blast {
  id: number;
  name: string;
  dateTime: string;
  subProjectNumber: string;
  comment: string;
  journalDocumentId: number;
  latitude: number;
  longitude: number;
  inputX: number;
  inputY: number;
  z: number;
  crs: string;
  isActive: boolean;
  blastColor: string;
  measuringPointIds: number[];
}

export interface TypeFilter {
  name: string;
  prefix: string;
  show: boolean;
}
export interface MapFilter {
  showActive: boolean;
  showInactive: boolean;
  showBlasts: boolean;
  from: Date;
  to: Date;
  types: TypeFilter[];
}

export const useMapFilter = () =>
  useState<MapFilter>(() => ({
    showActive: true,
    showInactive: false,
    showBlasts: true,
    from: EpochDate,
    to: new Date(),
    types: [
      {
        name: 'Vibration',
        prefix: 'Mark',
        show: true,
      },
      {
        name: 'Acceleration',
        prefix: 'Accel',
        show: true,
      },
      {
        name: 'Luftstöt',
        prefix: 'Luftstöt',
        show: true,
      },
      { name: 'Buller', prefix: 'Buller', show: true },
      {
        name: 'Stomljud',
        prefix: 'Stomljud',
        show: true,
      },
    ],
  }));

export const isMeasuringPointActive = (measuringPoint: MeasuringPointExt) => {
  const now = new Date();

  return measuringPoint?.cards
    .filter((x) => x.validFrom != null && x.validTo != null)
    .some((x) =>
      isWithinInterval(now, {
        start: parseISO(x.validFrom!),
        end: parseISO(x.validTo!),
      })
    );
};
